import styled from "styled-components";
import { Device } from '../Device/Device';

export const HeadingPz = styled.h2`
    //background-color: #a0a0a0;
    font-family: 'Millionaire';
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    margin:auto;
    font-size: ${props => props.size ? props.size : "108px"};
    letter-spacing: -1.5px;
    text-transform: uppercase;
    text-align: center;
    color: #000000;
    @media ${Device.mobile} {
        width: 350px;
        font-size: ${props => props.mSize ? props.mSize : "108px"};
    }
`

export const HPzL = styled(HeadingPz)`
    font-size: 108px;
    @media ${Device.mobile} { 
        font-size: 64px; 
    }
`

export const HPzM = styled(HeadingPz)`
    font-size: 104px;
    @media ${Device.mobile} { 
        font-size: 56px; 
    }
`

export const HPzS = styled(HeadingPz)`
    font-size: 80px;
    @media ${Device.mobile} { 
        font-size: 40px; 
    }
`

export const HPzXL = styled(HeadingPz)`
    font-size: 160px;
    @media ${Device.mobile} { 
        font-size: 76px; 
    }
`

/** Headings Romana */

const Heading = styled.h1`
    //background-color:grey;
    margin: auto;
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 140%;
    
    text-align: center;
    
    /* UI/Black */
    color: #000000;
    @media ${Device.mobile} {
        width: 350px;
    }
    ` 

    export const H1 = styled(Heading)`
        font-size: 60px;
        @media ${Device.mobile} { font-size: 36px; }
        `
    export const H2 = styled(Heading)`
        font-size: 56px;
        @media ${Device.mobile} { font-size: 34px; }
        `
    export const H3 = styled(Heading)`
        font-size: 48px;
        @media ${Device.mobile} { font-size: 32px; }
        `
    export const H4 = styled(Heading)`
        font-size: 40px;
        @media ${Device.mobile} { font-size: 30px; }
        `
    export const H5 = styled(Heading)`
        font-size: 32px;
        @media ${Device.mobile} { font-size: 28px; }
        `
    export const H6 = styled(Heading)`
        font-size: 24px;
        @media ${Device.mobile} { font-size: 22px; }
        `

    /** P */
export const TextBody = styled.div`
    width: ${props => props.width ? props.width : "572px"};
    margin: ${props => props.margin ? props.margin : "40px auto"};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;
    text-align: center;
    
    @media ${Device.mobile} {
        font-size:18px;
        width: ${props => props.mWidth ? props.mWidth : "350px"};
    }
    `
const RomanaBase = styled.p`
    font-family: 'Romana';
    font-style: normal;
    font-size: 16px;
    line-height: 160%;
    color: black;
`

export const RomanaSub = styled(RomanaBase)`
    font-size: 24px;
    font-weight: 400;
    margin-top: 24px;
`

const InterBase = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    line-height: 160%;
    color: black;
`

export const InterLegal = styled(InterBase)`
    
`

export const InterButton = styled(InterBase)`
    font-style: normal;
    font-size: 16px;
    letter-spacing:0.2px;
    text-transform: uppercase;
`

export const InterPL = styled(InterBase)`
    font-weight: 400;
    font-size: 24px;
    margin-top: 24px;
`

    
    /** small */
export const TextSmall = styled.div`
    width: ${props => props.width ? props.width : "572px"};
    margin: auto;
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 140%;
    z-index: 1;
    text-align: center;

    @media ${Device.mobile} {}
    @media ${Device.tablet} {}
    @media ${Device.laptop} {}
    @media ${Device.desktop} {}
    @media ${Device.desktopPlus} {}
    `