import styled from "styled-components";

import { EyebrowStyle, ParagraphMedium } from "../../../Utility/TextStyles"
import { HeadingH2 } from "../../../Utility/HeaderStyles"
import { Collapsible } from "../../Buttons/Collapsible";
import { StyledGridCol } from "../../../Utility/LayoutStyled";

export const FAQBlock = () => {
    return(
        <FAQContainer>
            <EyebrowStyle>FAQ</EyebrowStyle>
            <HeadingH2>Let's clarify some things</HeadingH2>
            <StyledGridCol>
                <Collapsible title='Is the app free?'>
                    <ParagraphMedium>Yes. All core features are free of charge. We do offer a thorough color analysis of all your unique features for a distinctive style specially designed for you. </ParagraphMedium>
                    <ParagraphMedium>Price of color palette analysis is $14.99.</ParagraphMedium>
                </Collapsible>
                <Collapsible title='What devices is the app available for?'>
                    <ParagraphMedium>At the moment only <strong>iOS</strong> and <strong>Android</strong> mobile devices are supported. Installing the app on your watch / tablet / car / air fryer will almost certainly not work as intended. </ParagraphMedium>
                </Collapsible>
                <Collapsible title='AI sounds scary. Is yours scary?' >
                <ParagraphMedium> If you have nothing to hide, you're good! Just kidding lol. You've been watching too many sci-fi movies. AI is here to serve us humans by learning and collecting information about us to offer us a highly personalized experience! How else could Cate know what you need before you do?</ParagraphMedium>
                </Collapsible>
            </StyledGridCol>
        </FAQContainer>
    )
}

const FAQContainer = styled.div`
    margin-top:80px;
    padding:0 16px 0 16px;
    display: ${props => props.display ? props.display : 'flex'};
    flex-direction: column;
    justify-content:center;
    align-items: center;
    position: relative;
    overflow: hidden;

    min-width:300px;
`

/** 
    <ParagraphMedium>Only when we forget to feed it.</ParagraphMedium>
    <ParagraphMedium>Which doesn't happen anymore... not since <i>the incident</i> &#128556;</ParagraphMedium>
 */