import styled from "styled-components";
import { Device } from "../ui/Device/Device";

// Romana styles

export const HeadingH1 = styled.h1`
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 140%;
    text-align:center;

    margin:auto;    
    color: #000000;
    @media ${Device.mobile} {
        font-size: 36px;
        line-height: 130%;
    }
` 
export const HeadingH2 = styled.h2`
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 140%;
    text-align:${props => props.align ? props.align : 'center'};
    max-width: ${props => props.maxWidth ? props.maxWidth : ''};
    width:${props => props.width ? props.width : ''};

    margin:auto;
    color: #000000;
    @media ${Device.mobile} {
        width:${props => props.mWidth ? props.mWidth : ''};
        font-size: 34px;
        line-height: 130%;
    }
`
export const HeadingH3 = styled.h3`
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 140%;
    text-align:center;

    margin:auto;
    color: #000000;
    @media ${Device.mobile} {
        font-size: 32px;
        line-height: 120%;
    }
`
export const HeadingH4 = styled.h4`
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    text-align:center;

    margin:auto;
    color: #000000;
    @media ${Device.mobile} {
        font-size: 30px;
        line-height: 120%;
    }
`
export const HeadingH5 = styled.h5`
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 140%;
    text-align:center;

    margin:auto;
    color: #000000;
    @media ${Device.mobile} {
        font-size: 28px;
        line-height: 120%;
    }
`
export const HeadingH6 = styled.h6`
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    text-align:center;

    margin:auto;
    color: #000000;
    @media ${Device.mobile} {
        font-size: 22px;
        line-height: 120%;
    }
`

// Pizzazz styles

export const PzLarge = styled.h2`
    font-family: 'Millionaire';
    font-style: italic;
    font-weight: 400;
    font-size: 108px;
    line-height: 120%;
    text-align:center;

    letter-spacing: -1.5px;
    text-transform: uppercase;

    margin:auto;
    color: #000000;
    @media ${Device.mobile} {
        font-size: 64px;
    }
`

export const PzMedium = styled.h2`
    font-family: 'Millionaire';
    font-style: italic;
    font-weight: 400;
    font-size: 104px;
    line-height: 120%;
    text-align:center;

    letter-spacing: -1.5px;
    text-transform: uppercase;

    margin:auto;
    color: #000000;
    @media ${Device.mobile} {
        font-size: 56px;
    }
    `
    
export const PzSmall = styled.h4`
    font-family: 'Millionaire';
    font-style: italic;
    font-weight: 400;
    font-size: 80px;
    line-height: 120%;
    text-align:center;

    letter-spacing: -1.5px;
    text-transform: uppercase;

    margin:auto;
    color: #000000;
    @media ${Device.mobile} {
        font-size: 40px;
    }
`

export const PzSuper = styled.h2`
    font-family: 'Millionaire';
    font-style: italic;
    font-weight: 400;
    font-size: 160px;
    line-height: 120%;
    text-align:center;

    letter-spacing: -1.5px;
    text-transform: uppercase;

    margin:auto;
    color: #000000;
    @media ${Device.mobile} {
        font-size: 76px;
    }
`