import { useState } from "react";
import styled from "styled-components"
import { Link } from "react-router-dom";

import { PrimaryButton } from "../Buttons/Buttons";
import { Device } from "../Device/Device";

import IMGbackground from "../../../img/catecut-grainitexture.jpg"

import { HeadingH1 } from "../../Utility/HeaderStyles";
import { EyebrowStyle } from "../../Utility/TextStyles";
import { navBlocks } from "../Solutions/SolutionsContent";


export const MenuContainer = (props) => {
    return(
        <>
        <StyledMenuContainer onClick={props.handleExpand} expand={props.expand}>
            <StyledMenuColumn>
                <EyebrowStyle>The regular deal</EyebrowStyle>
                <MenuLink onClick={props.handleExpand} reloadDocument to="/">
                    <MenuHeadingH1>Home</MenuHeadingH1>
                </MenuLink>
                <MenuLink onClick={props.handleExpand} to="/solutions">
                    <MenuHeadingH1>Solutions</MenuHeadingH1>
                </MenuLink>
                <MenuLink onClick={props.handleExpand} to="/support">
                    <MenuHeadingH1>Support</MenuHeadingH1>
                </MenuLink>
                <MenuLink onClick={props.handleExpand} to="/contact">
                    <MenuHeadingH1>Contact us</MenuHeadingH1>
                </MenuLink>
            </StyledMenuColumn>
        </StyledMenuContainer>
        </>
    )
}

export const HamburgerIcon = (props) => {
    return(
            <Hamburger 
                expand={props.expand} 
                onClick={props.handleClick}
            >
                <HamburgerBarUpper expand={props.expand}/>
                <HamburgerBarLower expand={props.expand}/>
            </Hamburger>
    )
}

const MenuLink = styled(Link)`
    display:flex;
    margin:auto;
    padding:0;
    text-decoration:none;
    width:max-content;
`

const MenuHeadingH1 = styled(HeadingH1)`
    width:max-content;
    border-bottom: 2px solid transparent;
    &:hover {
        border-bottom: 2px solid black;
    }
`

const StyledMenuContainer = styled.div`
    display: flex;
    flex-direction:column;
    align-content:center;
    justify-content:center;

    position:absolute;
    margin:auto;
    top:0px;
    width:100%;
    height:100vh;
    max-height: ${props => props.expand ? "100vh" : "0vh"};

    background:url(${IMGbackground});
    background-attachment: fixed;

    overflow:hidden;
    transition: max-height 200ms ease-in;
    // filter:drop-shadow(8px 8px 16px rgba(0,0,0,0.2));
    z-index:5;
`

const StyledMenuColumn = styled.div`
    position:relative;
    display:flex;
    flex-direction:column;
    align-content:center;
    justify-content:center;
    margin:auto;
    padding:0;
    gap:24px;
    z-index:20;
    width:max-content;
`


/** Menu Icon */

const Hamburger = styled.div`
    position:relative;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.expand ? "0px" : "8px"};
    padding: 10px 6px;

    width: 50px;
    height: 50px;

    background: ${props => props.expand ? "black" : "white"};
    border: 1.5px solid #000000;
    border-radius: 30px;

    z-index:10;

    &:hover{
        background: ${props => props.expand ? "white" : "black"};
    }
`
const HamburgerBar = styled.div`
    position: ${props => props.expand ? "absolute" : "relative"};
    display: flex;

    width: 20px;
    height: 0px;

    border: 1.5px solid ${props => props.expand ? "white" : "black"};
    background-color: ${props => props.expand ? "white" : "black"};
    
    z-index:11;

    ${Hamburger}:hover & {
        border: 1.5px solid ${props => props.expand ? "black" : "white"};
        background-color: ${props => props.expand ? "black" : "white"};
    }
`
const HamburgerBarUpper = styled(HamburgerBar)` 
    transform: ${props => props.expand ? "rotate(-45deg)" : "0"};
`
const HamburgerBarLower = styled(HamburgerBar)`
    transform: ${props => props.expand ? "rotate(45deg)" : "0"};
`