import styled from "styled-components";

import { Device } from "../ui/Device/Device";

export const DottedListContainer = styled.div`
    // margin-left: 24px;
    @media ${Device.mobile} {
        margin-left:20px;
        margin-right:20px;
    }
`

export const DottedList = styled.ul`
    margin-top: 24px;
    text-align: left;
    padding-left: 1.5em;
    @media ${Device.mobile} {
        padding-left: 2.5em;
    }
`

// TODO - check this out: https://styled-components.com/docs/advanced#referring-to-other-components
export const DottedListItem = styled.li`
    opacity: ${props => props.isActive ? 1 : 0.5};
    padding-left: 10px;  // creates a gap between the marker and the content
    // TODO - idea for line between dots : use nth-child(all but last) to use svg where dot has a line extending down with size of block
    ::marker {
        content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><circle cx="10" cy="10" r="9" stroke="rgb(57, 198, 181)" stroke-width="1.5"/><circle cx="10" cy="10" r="5" fill="${props => props.isActive && props.dotColor ? props.dotColor : "transparent"}"/></svg>');
    };
    // FIXME - cancels the marker above somehow...
    // :hover::marker {
    //     content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><circle cx="10" cy="10" r="9" stroke="rgb(57, 198, 181)" stroke-width="1.5"/></svg>');
    // };
    `
DottedListItem.defaultProps = {
    isActive: true,
}
