import styled from "styled-components";

import { HeadingH6, HeadingH2 } from "components/Utility/HeaderStyles";
import { ParagraphMedium, ParagraphSmall } from "components/Utility/TextStyles";
import { Device } from "../../Device/Device";
import { PrimaryButton } from "../../Buttons/Buttons";
import ReactPlayer from "react-player";
import React from "react";

export const SustainabilityBlock = () => {
    return (
        <>
        <HeadingH2>Solves modern retail challenges for greater sustainability</HeadingH2>
        <ParagraphMedium>We at Catecut are committed to solving modern retail challenges by combining advanced technologies with the best practices from the wardrobe styling profession. Our goal is to reduce the financial loss and CO2 footprint and garment waste from online retail returns to achieve greater sustainability globally.</ParagraphMedium>
        <StyledRow>
            <StyledColumn>
                <AlternatingPositionSection>
                    <LeftAlignHeading>Stops apparel returns before they happen</LeftAlignHeading>
                    <ParagraphSmall>Knowing that poor fit is the #1 cause of apparel returns, making up ~70% of clothing returns from online shopping, and that much of these garments end up in landfills or incinerators, we focus on stopping apparel returns before they happen, helping your shoppers find their best possible cut, fit and styles from your online store.</ParagraphSmall>
                </AlternatingPositionSection>
                <AlternatingPositionSection>
                    <LeftAlignHeading>Leverages advanced AI</LeftAlignHeading>
                    <ParagraphSmall>The proprietary AI models that power our styling solution tag and categorize your apparel inventory directly from your product imagery. Our styling solution is embedded directly into your online store, enabling you to deliver the best possible shopping experience to your customers.</ParagraphSmall>
                </AlternatingPositionSection>
                <AlternatingPositionSection>
                    <LeftAlignHeading>Tailors shopping experience to each customer</LeftAlignHeading>
                    <ParagraphSmall>While browsing your store, shoppers can click the stylist, indicate elements of their body structure, attributes and challenges. From there, Catecut tailors clothing recommendations from across your online store that are best suited for each individual buyer. This inspires buyer confidence, satisfaction and loyalty, all while mitigating apparel returns. Customers return less because they love what they buy!</ParagraphSmall>
                </AlternatingPositionSection>
            </StyledColumn>
            <VideoContainer>
                <ReactPlayer 
                    url="https://catecut-landing.s3.eu-west-1.amazonaws.com/Catecut-ai-stylist-how-it-works.mp4"
                    width="100%"
                    height="100%"
                    playing={true}
                    muted={true}
                    loop={true}
                    controls={false}
                    playsinline={true}
                />
            </VideoContainer>
        </StyledRow>
        <PrimaryButton padding='16px 8px' light='true' width="270px" href="/solutions">Learn more</PrimaryButton>
        </>
    )

}

const LeftAlignHeading = styled(HeadingH6)`
    margin-bottom: 24px;
    text-align: left;
`

const StyledRow = styled.div`
    margin-top: 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    @media ${Device.mobile} {
        flex-direction: column-reverse;
        gap: 40px;
    }
`

const StyledColumn = styled.div`
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
    margin-right: 40px;

    @media ${Device.mobile} {
        width: 100%;
        margin-right: 0px;
    }
`

const AlternatingPositionSection = styled.div`
    width: 60%;
    // TODO - only when in desktop
    :nth-child(even) {
        margin-left: auto;
    }

    @media ${Device.mobile} {
        width: 100%;
        margin-bottom: 30px;
    }
`

const VideoContainer = styled.div`
    width: 510px;
    // height: 315px;
    border: solid 1px rgba(217, 185, 192, 1);
    border-radius: 8px;
    background-color: white;
    // This is necessary so the square video corners don't overlap the rounded borders
    // Applying the borders to the ReactPlayer itself is no help because the video is in an internal div that we can't style AFAIK
    padding-top: 8px;
    padding-bottom: 8px;
    
    @media ${Device.mobile} {
        width: 95vw;
    }
`
