import React from "react"
import { H3, H5, InterLegal } from '../ui/Landing/LandingStyles';
import { LegalContainer, Container, UList, ListItem} from "../Utility/LegalStyles";
import Footer from '../Footers/Footer';
import Navbar from "../ui/Nav/Navbar";

const PrivacyPolicy = () => {
    return(
        <>
        <Navbar page="legal" />
        <Container>
            <LegalContainer>
                <H3>Privacy Policy</H3>
                <InterLegal>This Privacy Policy applies to information collected by Rebutia&trade; in connection with the services that Rebutia&trade; offers online. This includes information collected online through our websites, branded pages on third party platforms (i.e, social networking services), mobile applications, and through our direct marketing campaigns or other online communications.</InterLegal>
                <InterLegal><em>In order to provide you with personalized style recommendations, Rebutia&trade; Corporation needs you to provide us with certain Personal Information about yourself. We take the privacy of this Personal Information seriously. We provide this policy so that you can understand how we collect Personal Information from you, what Personal Information we collect, and how we use the Personal Information you provide.</em></InterLegal>
                <InterLegal>Last Modified: March 2019; This Privacy Policy is effective as of the date last modified.</InterLegal>
                <InterLegal>By submitting Personal Information to Rebutia&trade;, you consent to the collection and use of information by us as specified below unless you inform us otherwise by sending us an email. If we decide to change this Privacy Notice, we will make an effort to post those changes. We respect customers right to access, correct, request deletion or request restriction of our usage of their personal information as required by applicable law. This Privacy Policy applies to Personal Information provided to us about or relating to you, and from which, either alone or when connected with other information to which we may have access, you can be individually identified (&quot;Personal Information&quot;). That Personal Information is described in more detail below.</InterLegal>
                <InterLegal>Our Privacy Policy includes:</InterLegal>
                <UList>
                    <ListItem><InterLegal>We do not collect any more Personal Information about you than is necessary;</InterLegal></ListItem>
                    <ListItem><InterLegal>Unless you agree otherwise, we only use your Personal Information for the purposes we specify in this Privacy Policy;</InterLegal></ListItem>
                    <ListItem><InterLegal>We do not keep your Personal Information if it is no longer needed;</InterLegal></ListItem>
                    <ListItem><InterLegal>Other than as we specify in this Privacy Policy, we do not share your Personal Information with third parties.</InterLegal></ListItem>
                </UList>
                <InterLegal>This Privacy Policy addresses the following questions:</InterLegal>
                <UList>
                    <ListItem><InterLegal>1. What is the Personal Information that we collect?</InterLegal></ListItem>
                    <ListItem><InterLegal>2. Why do we collect Personal Impersonal Information?</InterLegal></ListItem>
                    <ListItem><InterLegal>3. How do we use Personal Information? </InterLegal></ListItem>
                    <ListItem><InterLegal>4. With whom do we share Personal Information?</InterLegal></ListItem>
                    <ListItem><InterLegal>5. How do we secure Personal Information?</InterLegal></ListItem>
                    <ListItem><InterLegal>6. How do we respect your choices about your Personal Information?</InterLegal></ListItem>
                    <ListItem><InterLegal>7. Whom do I contact if I have questions or concerns?</InterLegal></ListItem>
                    <ListItem><InterLegal>8. Additional Terms.</InterLegal></ListItem>
                </UList>
                <H3>1. What Personal Information do we collect and how is it collected?</H3>
                <InterLegal>(a) Personal Information you provide to us.</InterLegal>
                <InterLegal>Our profile will request Personal Information such as your age, gender, size, body figure, structure, style, skin colour, hair colour and height. We may also request your email address, which is recommended, but not mandatory to use our Service. Rebutia retail partners are third parties or affiliates for whom Rebutia provides its online style recommendation service.</InterLegal>
                <InterLegal>When you want to make a purchase you are redirected to a retailer&#39;s site to complete the purchase, the Personal Information you provide through the retail partner&#39;s website or mobile application (&quot;Partner Site&quot;) will be subject to the retail partner&#39;s privacy policy; however, the Rebutia Privacy Policy will apply to those aspects of your Personal Information that are provided to Rebutia in order to perform the Rebutia Service and the data contained in your profile that may be transmitted back to the retail partner. Once the data contained in the profile is transmitted back to the retail partner, such data will be subject to the retail partner&#39;s privacy policy (which may be different than our privacy policy; please see Section 4 above regarding third party privacy policies).</InterLegal>
                <InterLegal>(b) Personal Information automatically collected.</InterLegal>
                <InterLegal>While you use the Rebutia Service, we automatically record information that your browser sends whenever you visit a website. For example, we may receive: the name of the domain and host from which you access the Internet, the Internet Protocol (IP) address of the computer you are using, the date and time you access our Service, which apparel items you view and/or purchase, and the Internet address of the website from which you accessed the Service. For clarity, we do not store the IP address of the computer you are using. We use this information to measure the usage of our Service, and to understand how to serve you better. The most common sources of this Personal Information include:</InterLegal>
                <UList>
                    <ListItem><InterLegal>Cookies</InterLegal></ListItem>
                    <ListItem><InterLegal>Service Analytics</InterLegal></ListItem>
                    <ListItem><InterLegal>Pixel tags or clear Graphics Interchange Format files, known as GIFs</InterLegal></ListItem>
                    <ListItem><InterLegal>Occasionally; retail partners, business partners, contractors, shared databases, and other third parties</InterLegal></ListItem>
                </UList>
                <InterLegal>We provide a summary description of each of these circumstances below.</InterLegal>
                <H5>Cookies</H5>
                <InterLegal>A cookie is a message given to a web browser by a web server. The browser stores the message in a text file. Cookies help us to: (1) associate you with your Profile and enable you to update and modify your Profile; (2) speed navigation and provide you with your personalized style recommendations; (3) remember Personal Information you gave so you don&#39;t have to re-enter it; (4) determine the effectiveness of some of our marketing efforts and communications; and (5) count the total number of visitors, pages viewed, and the total number of banners displayed. Browsers are typically set to create cookies automatically. You can choose to have your browser notify you when cookies are being written to your computer or accessed, or you can disable cookies entirely. If you disable cookies, however, you will not be able to create a Rebutia Profile, and therefore you will not be able to receive the benefit of our Service. Also, by not using cookies, features within our Service or a Partner Site may not function properly.</InterLegal>
                <H5>Tags and pixels</H5>
                <InterLegal>To help us understand the effectiveness of select communications and marketing efforts, we may use &quot;message format&quot; and &quot;message open&quot; sensing technologies that use pixels and tags Pixel tags are typically single pixel, transparent GIF images that are added to a web page. Even though the pixel tag is virtually invisible, it is still served just like any other image you may see online. This allows us to read and record the cookie with the unique ID and the extended information it needs to record.</InterLegal>
                <H3>2. Purpose of collecting your Personal Information?</H3>
                <InterLegal>We collect your Personal Information to provide you with our Service and to administer our business. It allows us to provide you with easy access to our products and services, with a particular focus on things that may be of most interest to you. Your Personal Information also allows us to communicate with you about special offers, promotions, and other marketing programs and news that may be of interest to you.</InterLegal>
                <H3>3. How do we use Personal Information?</H3>
                <InterLegal>We use your Personal Information to provide you with our service through our website, to provide you with a superior customer experience, and to administer our business. We use your Personal Information to:</InterLegal>
                <UList>
                    <ListItem><InterLegal>Create and provide recommendations on style;</InterLegal></ListItem>
                    <ListItem><InterLegal>To ensure the clothes fit, it is a good combination for existing wardrobe,</InterLegal></ListItem>
                    <ListItem><InterLegal>find the best combination of clothes.</InterLegal></ListItem>
                    <ListItem><InterLegal>find appropriate clothes for the context of your life.</InterLegal></ListItem>
                    <ListItem><InterLegal>Analyze the style and lifetime of products;</InterLegal></ListItem>
                    <ListItem><InterLegal>Create your personal account which you can log into from any device;</InterLegal></ListItem>
                    <ListItem><InterLegal>Provide you with special offers, promotions, and other marketing programs and news that may be of interest to you;</InterLegal></ListItem>
                    <ListItem><InterLegal>Answer questions you ask of us regarding the service or recommendations;</InterLegal></ListItem>
                    <ListItem><InterLegal>Make communications necessary to notify you regarding security, privacy, and administrative issues;</InterLegal></ListItem>
                    <ListItem><InterLegal>Validate you are of legal age to use our services;</InterLegal></ListItem>
                    <ListItem><InterLegal>Test and improve our systems and services;</InterLegal></ListItem>
                </UList>
                
                <H5>Type of data transferred</H5>
                <InterLegal>- What &ldquo;build/style&rdquo;-type of people are frequently getting retailers clothes? How well do the retailers products suit the profiled customers? How many products are considered, seriously, before a decision is made? Recommended alterations. What is the lifetime and usage pattern of retailers items? What are the traffic trends to retailers store?</InterLegal>
                <InterLegal>We will only keep your data for as long as necessary to carry out our services or as long as we are required by law. After this, your personal data will be deleted.</InterLegal>
                <InterLegal>Non-personal data is used as described above and in other ways as permitted by applicable laws, including combining non-personal data with personal data.</InterLegal>
                <H3>4. Sharing of Personal Information?</H3>
                <InterLegal>Protecting the privacy and security of your Personal Information is a priority. EXCEPT AS OTHERWISE DESCRIBED IN THIS PRIVACY POLICY, Rebutia DOES NOT SELL OR RENT YOUR PERSONAL INFORMATION TO THIRD PARTIES. Rebutia may share a copy of your Profile, which may include your email address (if provided) with: (i) our retail partners, so they can better personalize apparel offerings to you; (ii) to third parties in certain business relationships, such as, but not limited to, co-branded offerings where we may offer a service or feature that is co-branded by Rebutia and the third party. Rebutia treatment of that Personal Information is governed by this privacy policy, but the third party&#39;s treatment of your Personal Information will be subject to the third party&#39;s privacy policy.</InterLegal>
                <InterLegal>In addition, Rebutia may share any of your Personal Information with third parties as follows:</InterLegal>
                <InterLegal>Law Enforcement Officials and as Required by Law.</InterLegal>
                <InterLegal>Rebutia may release any or all of your Personal Information to third parties when we determine, in our reasonable judgment, that it is necessary to (a) comply with the law, regulation, legal process, or enforceable governmental request; (b) enforce or apply the terms of any of our policies or user agreements; or (c) protect the rights, property or safety of Rebutia, our employees, our customers, users, or others.</InterLegal>
                <H3>5. How do we secure your Personal Information?</H3>
                <InterLegal>We take appropriate security measures, including physical, technological, and procedural measures, to help to safeguard your Personal Information and to prevent unauthorized access and disclosure. In addition, we ask you to add symbols, numbers and a mix of characters that forces passwords to be harder to discover. We will use centralized user directory, so if something happens, we can immediately remove access to all applications in one go. We will also Monitor login attempts and user activities to provide insight into how best to prevent unauthorized access. No method of transmission over the Internet, or method of electronic storage, however, is 100% secure. Therefore, we cannot guarantee its absolute security. We acknowledge and agree that your Personal Information may be processed and stored in countries outside of the European Union including, without limitation, the United States.</InterLegal>
                <H3>6. How do we respect your choices about your Personal Information?</H3>
                <InterLegal>When you interact with our Service in certain ways, you may be eligible to receive certain marketing-related and promotional communications as well as special offers (collectively &quot;Promotional Communications&quot;) from Rebutia or third parties with whom Rebutia has a business relationship and those communications may include advertisements. The most common of these circumstances may include:</InterLegal>
                <UList>
                    <ListItem><InterLegal>Creating a Profile</InterLegal></ListItem>
                    <ListItem><InterLegal>Signing up to receive newsletters or email notices</InterLegal></ListItem>
                    <ListItem><InterLegal>Entering a contest</InterLegal></ListItem>
                </UList>
                <InterLegal>If, at any time, you wish to no longer receive Promotional Communications from us, you will be able to opt-out of receiving Promotional Communications. However, you may continue to receive occasional emails relating to Rebutia Profile update Personal Information, and/or other security or business-related communications. Although the primary purpose of these emails is not to provide you with promotional material, they may contain within them some promotional material.</InterLegal>
                <InterLegal>We provide you with the ability to access, correct, change or delete the Personal Information in your Profile at any time. You can send us an email info@rebutia.co to access, correct, request deletion or request restriction of our usage of your personal information.</InterLegal>
                <H3>7. Questions or concerns?</H3>
                <InterLegal>If you have any questions about this Privacy Policy, please contact us at info@therebutia.com</InterLegal>
                <H3>8. Additional Terms</H3>
                <InterLegal>By doing business or interacting with Rebutia, you are consenting to the transfer of your Personal Information to facilities located in the United States and Europe.</InterLegal>
                <H5>Children&#39;s and Minor&#39;s Privacy.</H5>
                <InterLegal>The Rebutia Service is not designed to attract minors, in particular children under the age of 16. Rebutia does not market to or knowingly collect Personal Information from anyone under the age of 16. Children should always get permission from their parents before sending any Personal Information about themselves (such as their names, email addresses, and phone numbers) to anyone over the Internet. Please be advised that whenever you click on the links and banners that take you to a third party website, you will be subject to the third party&#39;s privacy policies. This Privacy Policy applies solely to Personal Information collected by Rebutia.</InterLegal>
                <InterLegal>By doing business with or interacting with Rebutia in the manner described in this Privacy Policy at any time on or after the Effective Date, you are accepting the practices described in this Privacy Policy and you expressly consent to the application of this Privacy Policy to the collection, storage, use and disclosure of your Personal Information as described.</InterLegal>
            </LegalContainer>
            <Footer type="legal"/>
            </Container>
        </>
    )
}

export default PrivacyPolicy;