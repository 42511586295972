import styled from "styled-components";
import { Device } from "../ui/Device/Device";


export const StyledImage = styled.img`
    position: relative;
    background: transparent;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    z-index: ${props => props.zIndex || "4"};
    border: 0.5px solid ${props => props.borderColor || "black"};
    border-radius: 8px;
`

export const StackedImage = styled(StyledImage)`
    position: absolute;
    width: auto;
    height: auto;
    max-width: ${props => props.maxWidth || "100%"};
    max-height: ${props => props.maxHeight || "100%"};
    top: ${props => ["top", "center"].includes(props.verticalPosition) ? "0px" : "auto"};
    bottom: ${props => ["bottom", "center"].includes(props.verticalPosition) ? "0px" : "auto"};
    left:  ${props => ["left", "center"].includes(props.horizontalPosition) ? "0px" : "auto"};
    right:  ${props => ["right", "center"].includes(props.horizontalPosition) ? "0px" : "auto"};
    margin: auto;
`
StackedImage.defaultProps = {
    verticalPosition: "top",
    horizontalPosition: "left"
}

export const StackedImageContainer = styled.div`
    position: relative;
    width: ${props => props.width};
    height: ${props => props.height};
    
    // TODO - this should be the responsibility of the extended components
    @media ${Device.mobile} {
        width: 95vw;
        height: 280px;
    }
`
