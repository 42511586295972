import { React, useState } from 'react'
import styled from "styled-components";

import { Device } from '../Device/Device';

import { HeadingH6 } from '../../Utility/HeaderStyles';

export const Collapsible = (props) => {
    
    const [isExpanded, setExpanding] = useState(false);

    const handleCollapsibleClick = () => {
        setExpanding(!isExpanded)
    }

    return(
        <StyledCollapsible onClick={handleCollapsibleClick}>
            <StyledHeading>
                <StyledH6>{props.title}</StyledH6>
                <StyledExpand>
                    <StyledHorizontalIndicator expand={isExpanded}/> 
                    <StyledVerticalIndicator expand={isExpanded}/>
                </StyledExpand>
            </StyledHeading>
            <StyledContent expand={isExpanded}>
                <BodyContent>{props.children}</BodyContent>
            </StyledContent>
        </StyledCollapsible>
    )
}

const StyledH6 = styled(HeadingH6)`
    text-align: left;
    padding-left:22px;
    margin:0;
`

const StyledExpand = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
`

const StyledHorizontalIndicator = styled.div`
    position: absolute;
    left: 6.41%;
    right: 6.41%;
    top: 45.9%;
    bottom: 45.9%;
    background: #000000;
    border-radius: 6px;

    transform: ${props => props.expand ? "rotate(-45deg)" : "rotate(-90deg)"};
    transition: transform 200ms ease-in;
`
const StyledVerticalIndicator = styled.div`
    position: absolute;
    left: 6.41%;
    right: 6.41%;
    top: 45.9%;
    bottom: 45.9%;
    background: #000000;
    border-radius: 6px;

    transform: ${props => props.expand ? "rotate(45deg)" : "rotate(0deg)"};
    transition: transform 200ms ease-in;
`

const BodyContent = styled.div``

const StyledCollapsible = styled.button`
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    cursor: pointer;
    max-width: 660px;

    background: #FFFFFF;

    border: 1.5px solid #000000;
    border-radius: 16px;

    @media ${Device.mobile} {
        width:90vw;
    }
`

const StyledHeading = styled.div`

    justify-content: space-between;
    align-items:center;
    position:relative;
    display:flex;
    flex-direction: row;
    background:transparent;
    padding: 18px;
    width: 100%;
    text-align: start;
    outline: none;
    font-size: 15px;
`
const StyledContent = styled.div`
    padding: 0 40px 0 40px;
    max-height: ${props => props.expand ? "600px" : "0"};
    overflow:hidden;
    transition: max-height 200ms ease-in;
    text-align: start;
    white-space: pre-line;
`