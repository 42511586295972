import styled from "styled-components"

export const StyledGridRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: ${props => props.grow ? props.grow : ''};
    flex-shrink: ${props => props.shrink ? props.shrink : ''};
    justify-content:${props => props.justify ? props.justify : ''};
    align-items:${props => props.align ? props.align : ''};
    margin: ${props => props.margin ? props.margin : '40px auto auto auto'};
    gap: ${props => props.gap ? props.gap : '16px' };

    width: ${props => props.width ? props.width : ''};
    max-width: ${props => props.maxWidth ? props.maxWidth : ''};

`

export const StyledGridCol = styled.div`
    position:relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: ${props => props.grow ? props.grow : ''};
    flex-shrink: ${props => props.shrink ? props.shrink : ''};
    margin: ${props => props.margin ? props.margin : '40px auto auto auto'};
    justify-content:${props => props.justify ? props.justify : ''};
    align-items:${props => props.align ? props.align : ''};
    gap: ${props => props.gap ? props.gap : '16px' };
    width: ${props => props.width ? props.width : ''};
    max-width: ${props => props.maxWidth ? props.maxWidth : ''};
`

