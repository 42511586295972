import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { GlobalStyles } from './global';
import { theme } from './theme'
import ScrollToTop from './components/ui/Nav/ScrollToTop';
import MainContainer from './components/Pages/Main';
import MarketplaceContainer from './components/Pages/Marketplace';
import TermsConditions from './components/Pages/TermsConditions';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import SolutionsContainer from "./components/Pages/Solutions";
import { BlogContainer } from './components/Pages/Blog';
import { SupportContainer } from './components/Pages/Support';
import { ContactContainer } from './components/Pages/Contact';
import { ContactSalesContainer } from './components/Pages/ContactSales';
import { B2BContainer } from './components/Pages/B2B'

import { ApolloProvider } from '@apollo/client';
import { GraphQLClient } from './components/Apollo/Apollo';

import WebFont from 'webfontloader';


function App() {

  // Loads the custom fonts
  WebFont.load({
    custom: {
      families: ['Inter', 'Inter Bold', 'Millionaire', 'Romana'],
      urls: ["/assets/font.css"]
    }
  })
  
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={GraphQLClient}>
          <GlobalStyles />
          <ScrollToTop /> 
          <Routes>
            <Route path="/" element={ <MainContainer /> } />
            <Route path="solutions" element={ <SolutionsContainer /> } />
            {/* <Route path="marketplace" element={ <MarketplaceContainer /> } /> */}
            <Route path="support" element={ <SupportContainer/>} />
            <Route path="terms" element={ <TermsConditions /> } />
            <Route path="privacy" element={ <PrivacyPolicy /> } />
            <Route path="contact" element={ <ContactContainer /> } />
            <Route path="sales" element={ <ContactSalesContainer /> } />
            <Route path="b2b" element={ <B2BContainer /> } />
            <Route path="blog" element={ <BlogContainer /> } >
              <Route path=":page" element={ <BlogContainer/> } />
              <Route path="article/:article" element={ <BlogContainer/> } />
            </Route>
          </Routes>
        </ApolloProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;