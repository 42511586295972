import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import { theme } from '../../../theme';
import { Device } from '../Device/Device';
import { InterPL, RomanaSub } from '../Landing/LandingStyles';

const MailchimpForm = ({audience, status, message, onValidated}) => {
    const [email, setEmail] = useState('');
    const [inputError, setInputError] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState(status);

    const clearFields = () => {
        setInputError(false);
        setEmail('');
        setSubscriptionStatus(null);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setInputError(validateInput([email]));
        if(!inputError){   
            email &&
            email.indexOf("@") > -1 &&
            onValidated({EMAIL: email});
        }
    }

    const validateInput = values => { 
        if (values.some(f=>f === "") || (values[0].indexOf("@") === -1 || values[0].indexOf(".") === -1)){
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        setInputError(false);
    }, [email])

    useEffect(() => {
        setSubscriptionStatus(status) // mirror status prop with a state to allow for resetting the form via clearFields()
        if(status === "error"){
            setInputError(true)
        }
    },[status])

    useEffect(() => {
        if(message && (message.toString().charAt(0) === '0')){
            console.log("Server message: "+message)
            setInputError(true)
        }
    }, [message])

    return (
        <StyledSubscription>
            {subscriptionStatus !== "success" 
                ? (<><StyledMessage>
                    {audience === "notify" ? 
                        <InterPLMessage>Reserve your spot and be notified the moment our app goes live</InterPLMessage>
                    :
                        <RomanaSubMessage>Do you want inside tips from our stylists? Subscribe to our newsletter!</RomanaSubMessage>
                    }</StyledMessage>
                    <StyledForm
                        error={inputError}
                        onSubmit={(e)=> handleSubmit(e)}
                    >
                        <StyledFormOutline error={inputError}/>
                        <StyledInput 
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            placeholder="Write your email"
                        />
                        {audience === "notify" ? 
                        <StyledSubmit
                            value="Sign up"
                            type="submit"
                            error={inputError}
                        />
                        :
                        <StyledSubmit
                            value="Subscribe"
                            type="submit"
                            error={inputError}
                        />
                    }
                    </StyledForm>
                    {inputError 
                        ? <StyledWarning>
                            <StyledWarningArrow />
                            <StyledWarningLabel>Oops! There seems to be something wrong with the address. Can you check it again?</StyledWarningLabel>
                        </StyledWarning>
                    : null}
                </>) 
                : subscriptionStatus === "success" ? 
                        <StyledMessage>
                            🎉Good news!🎉 You are on our mailing list with the following address: <i>{email}</i>.
                            <StyledMessageReset onClick={clearFields}>Sign up again</StyledMessageReset>
                        </StyledMessage>
                    : null 
            }
        </StyledSubscription>
        )
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 435px;
    height: 60px;
    gap: 16px;

    pointer-events:none;
    background: white;
    border: 2px solid white;
    border-radius: 30px;

    z-index:3;

    @media ${Device.mobile} {
        width: 300px;
    }
`

const StyledFormOutline = styled.div`
    position:absolute;
    width: 435px;
    height: 60px;
    gap: 16px;
    background:transparent;
    border: 2px solid ${props => props.error ? theme.elementWarning : 'black'};
    border-radius: 30px;
    z-index:4;
    pointer-events:none;
    @media ${Device.mobile} {
        width: 300px;
    }
`

const StyledInput = styled.input`
    width: 250px;
    height: 24px;
    border: 0px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    margin-left: 30px;
    align-items: center;
    text-align: left;

    pointer-events:auto;
    z-index:3;
    /* UI/Black 60 */
    color: ${theme.UIBlack60};

    &:focus {
        outline:none;
    }
    @media ${Device.mobile} {
        width: 125px;
    }
`
const StyledSubmit = styled.input`
    color: ${props => props.error ? theme.UIBlack60 : 'white'};
    position:relative;
    display:flex;
    text-align:center;
    align-items:center;
    justify-content:center;
    gap: 16px;
    padding:0;
    
    left: 3px;
    z-index: 3;
    min-width: 138px;
    height: 58px;

    background: ${props => props.error ? theme.elementDisabled : 'black'};
    border: 2px solid transparent;
    border-radius: 30px;

    /* Non-responsive/Button-Romana */
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;

    letter-spacing: 0.3px;
    cursor: pointer;
    pointer-events:auto;

    -webkit-appearance:none;

    @media ${Device.mobile} {
        font-size: 20px;
        min-width: 120px;
    }
`

const StyledWarning = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    width: 456px;
    height: 86px;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    @media ${Device.mobile} {
        width: 300px;
    }
`

const StyledWarningLabel = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 24px;

    width: 456px;
    height: 76px;

    /* Warnings/Red Background */
    background: rgba(206, 64, 32, 0.15);

    /* Warnings/Red */
    border: 2px solid ${theme.elementWarning};
    border-radius: 16px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    
    /* or 22px */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    color: #000000;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    @media ${Device.mobile} {
        width: 300px;
    }
`

const StyledWarningArrow = styled.div`
    position:relative;
    width: 0; 
    height: 0; 
    left: 0px;
    top: 0px;

    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    
    border-bottom: 11px solid ${theme.elementWarning};
`

const StyledMessage = styled.div`
    width: 456px;
    margin-bottom:12px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%; 
    
    text-align: center;

    color: #000000;

    @media ${Device.mobile} {
        width: 300px;
        font-size: 16px;
    }
`

const StyledMessageReset = styled.div`
    margin-top: 40px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    cursor: pointer;
`

const StyledSubscription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const RomanaSubMessage = styled(RomanaSub)`
    @media ${Device.mobile}{
        font-size:18px;
    }
`

const InterPLMessage = styled(InterPL)`
    @media ${Device.mobile}{
        font-size:18px;
    }
`

export default MailchimpForm;
