import styled from "styled-components"
import { Device } from "../Device/Device";
import { useState, useEffect } from "react";
import { invokeLambdaFunction } from "../../Utility/invokeLambdaFunction";
import { EyebrowStyle, ParagraphMedium, ParagraphSmall } from "../../Utility/TextStyles"
import { PrimaryButton } from "../Buttons/Buttons";
import validator from "validator";
import { theme } from "../../../theme";

export const ContactForm = (props) => {

    const [result, setResult] = useState();
    const [isExpanded, setExpanded] = useState(true);
    const [validationResult, setValidation] = useState();

    const resultMessage = () => {
        if (result && !isExpanded){
            return "Thank you for your message! Someone at Catecut will read it and be in touch shortly."
        } else {
            return(
                <>
                    Something went wrong while sending the message. 
                    Please try again, or drop us a line directly at&nbsp;
                    <StyledMessageLink href={`mailto:${process.env.REACT_APP_FORM_SUPPORT_ADDRESS}`}>
                        {process.env.REACT_APP_FORM_SUPPORT_ADDRESS}
                    </StyledMessageLink>
                </>
                )
        }
    }

    const ValidateForm = (e) => {
        if(!validator.isEmail(e.target.email.value)){
            return {validated: false, field: "email", message: "There seems to be something wrong with the address. Can you check it again?"}
        }
        if((e.target.fullname.value).length < 1){
            return {validated: false, field: "fullname", message: "Please put in a name for the message"}
        }
        if((e.target.subject.value).length < 1){
            return {validated: false, field: "subject", message: "Please put in a subject for the message"}
        }
        if((e.target.message.value).length < 1){
            return {validated: false, field: "message", message: "Please write a message to send"}
        }

        return {validated: true, field: "none", message: "Validation successful"}
    }

    const handleReset = () => {
        document.getElementById(props.formId).reset()
        setExpanded(true)
        setValidation('')
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const vResult = ValidateForm(e)
        setValidation(vResult)

        if(!vResult.validated){
            return vResult.message
        }

        const newEmail = e.target.email.value;
        const newSubject = `Contact request [${props.selected.type}]: ${e.target.subject.value}`;
        const newMessage = `${e.target.fullname.value} (${e.target.email.value}) asks: \n----\n ${e.target.message.value}`;

        const payload = {
            body: { 
                email: newEmail, 
                subject: newSubject, 
                message: newMessage
            },
            address: props.address
        };
        const lambdaResponse = await invokeLambdaFunction('handleContactForm', payload);
        setResult(lambdaResponse.result === "Success" ? true : false);
        setExpanded(false);
    }

    return(
        <Container>
            <StyledForm expand={isExpanded} onSubmit={handleSubmit} id={props.formId}>
                <StyledLabel htmlFor="email"><EyebrowStyle>E-mail</EyebrowStyle></StyledLabel>
                <StyledInput type="text" id="email" name="email" placeholder="Your e-mail address" />
                {validationResult?.field === "email" ? <StyledWarning htmlFor="email"><StyledWarningMessage>{validationResult.message}</StyledWarningMessage></StyledWarning> : <></>}

                <StyledLabel htmlFor="name"><EyebrowStyle>Full Name</EyebrowStyle></StyledLabel>
                <StyledInput type="text" id="name" name="fullname" placeholder="Your name" />
                {validationResult?.field === "fullname" ? <StyledWarning htmlFor="name"><StyledWarningMessage>{validationResult.message}</StyledWarningMessage></StyledWarning> : <></>}


                <StyledLabel htmlFor="subject"><EyebrowStyle>Subject</EyebrowStyle></StyledLabel>
                <StyledInput id="subject" name="subject" placeholder="Subject of your message" />
                {validationResult?.field === "subject" ? <StyledWarning htmlFor="subject"><StyledWarningMessage>{validationResult.message}</StyledWarningMessage></StyledWarning> : <></>}


                <StyledLabel htmlFor="message"><EyebrowStyle>Message</EyebrowStyle></StyledLabel>
                <StyledTextarea id="message" name="message" placeholder="Your message" />
                {validationResult?.field === "message" ? <StyledWarning htmlFor="message"><StyledWarningMessage>{validationResult.message}</StyledWarningMessage></StyledWarning> : <></>}
                <StyledSubmit type="submit" value="Send message"/>
            </StyledForm>
            <MessageContainer expand={isExpanded}>
                <StyledMessage>{resultMessage()}</StyledMessage>
                <StyledMessageButton onClick={handleReset} light={true}>Send another message</StyledMessageButton>
            </MessageContainer>
        </Container>
    )
}

const Container = styled.div`
    padding: 32px 8px 32px 8px;
`

const MessageContainer = styled.div`
    display:${props => props.expand ? "none" : "flex"};
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:80px;
`

const StyledMessage = styled(ParagraphMedium)`
    margin-bottom:40px;
`

const StyledMessageLink = styled.a`
    color:black;
    font-style:italic;
`

const StyledWarning = styled.label`
    position:relative;
    height:0px;
`
const StyledWarningMessage = styled(ParagraphSmall)`
    color: ${theme.elementWarning};
    margin:0;
    padding:0;
`
const StyledMessageButton = styled(PrimaryButton)`
    height:54px;


    
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.3px;
    color: black
`
const StyledForm = styled.form`
    display:${props => props.expand ? "flex" : "none"};
    flex-direction:column;
    justify-content:center;
    align-items:center;
    max-width:100%;
`

const StyledLabel = styled.label`
    margin-top: 32px;
`

const StyledInput = styled.input`
    display: flex;
    flex: none;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    padding: 16px 16px;
    gap: 16px;

    width: 303px;
    height: 60px;

    background: #FFFFFF;
    border: 1.5px solid #000000;
    border-radius: 16px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: start;

    color: #4E4E4E;
`

const StyledSubmit = styled.input`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self:center;
    padding: 16px 32px;
    margin: 32px 0 0 0;
    gap: 16px;

    width: 231px;
    height: 54px;

    background: white;
    border-radius: 30px;
    border: 1.5px solid black;

    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #000000;

    &:hover{
        border: 0;
        background: black;
        color:white;
    }
`

const StyledTextarea = styled.textarea`
    display: flex;
    flex: none;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    padding: 16px 16px;
    gap: 16px;

    width: 80%;
    height: 20em;

    background: #FFFFFF;
    border: 1.5px solid #000000;
    border-radius: 4px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: start;

    color: #4E4E4E;
    resize: none;

    // Firefox scrollbar
    overflow-y: scroll;
    scrollbar-color: #e4e4e4 white;
    scrollbar-width: thin;

    // Webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: white;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background: #e4e4e4;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media ${Device.mobile} {
        width:100%;
    };
`
