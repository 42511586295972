import React from 'react';
import styled from 'styled-components';
import { Device } from '../Device/Device';
import ImgCarrier from './img/grand-carrier.gif';
import ImgCat from './img/reality-cat.gif';
import ImgRetail from './img/retail-therapy.gif';
import ImgRobot from './img/robo-dance.gif';
import ImgMockup from './img/mockup-desktop.png';
import ImgMockupMobile from './img/mockup-mobile.png';
import ImgLanding1 from './img/catecut-landing-image-01.png'
import ImgLanding2 from './img/catecut-landing-image-02.png'
import ImgLanding3 from './img/catecut-landing-image-03.png'

export const IMGLanding3 = () => { return(
    <StyledLandingImage height='800px' mHeight='500px' src={ImgLanding3} alt="All kinds of dramatic posery" />
)}

export const IMGLanding2 = () => { return(
    <StyledLandingImage height='457px' mHeight='350px' src={ImgLanding2} alt="Woman in a shiny purple dress" />
)}

export const IMGLanding1 = () => { return(
    <StyledLandingImage filter='drop-shadow(2px 2px 4px rgba(0,0,0,0.15))' src={ImgLanding1} alt="Example of Catecut app" />
)}

export const IMGCarrier = () => { return(
    <StyledCarrier src={ImgCarrier} alt="A woman and her beast of burden go shopping" />
)}
export const IMGRetail = () => { return(
    <StyledRetail src={ImgRetail} alt="Woman flipping between coats on a rack" />
)}
export const IMGRobot = () => { return(
    <StyledRobot src={ImgRobot} alt="A robot celebrates robot life by robot dancing" />
)}
export const IMGCat = () => { return(
    <StyledCat src={ImgCat} alt="Cat bending reality to its adorable will" />
)}
export const IMGMockup = (props) => {
    if (props.windowWidth > 600) {
        return(
            <StyledMockup src={ImgMockup} alt="A picture of the Catecut app" />
        ) 
    } else {
        return(
            <StyledMockupMobile src={ImgMockupMobile} windowWidth={props.windowWidth} alt="A picture of the Catecut app" />
        )
    }
    
}

const StyledImage = styled.img`
    background: transparent;
    position: relative;
    object-fit:cover;
    z-index: 4;
`

const StyledBrandImages = styled(StyledImage)`
    width: 300px;
    height: 300px;

    // border-radius:50%;
    // filter:drop-shadow(8px 8px 16px rgba(0,0,0,0.2));

    @media ${Device.mobile} {
        height: 180px;
        width: 180px;
    }
`

const StyledCarrier = styled(StyledBrandImages)`
    transform: rotate(-6.68deg);
`
const StyledRobot = styled(StyledBrandImages)`
    transform: rotate(13deg);
`
const StyledRetail = styled(StyledBrandImages)`
    transform: rotate(-12.78deg);
`
const StyledCat = styled(StyledBrandImages)`
    transform: rotate(12deg);
`

const StyledMockup = styled(StyledImage)`
    margin:auto;
    width: 1116px;
    height: 917px;
`

const StyledMockupMobile = styled(StyledImage)`
/* 780 x 2087 */
    margin:auto;
    max-width:100%;
`

const StyledLandingImage = styled(StyledImage)`
    object-fit:contain;
    height:${props => props.height ? props.height : '457px'};
    margin:auto;
    filter: ${props => props.filter ? props.filter : ''};
    @media ${Device.mobile} {
        height: ${props => props.mHeight ? props.mHeight : ''};
    }
`