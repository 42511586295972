import { React, useEffect, useState, useRef } from 'react'
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { theme } from '../../theme';

import PageNav from '../ui/Nav/PageNav';
import Navbar from '../ui/Nav/Navbar';
import DotContainer from '../ui/DotContainer/DotContainer';
import { SolutionsContent } from '../ui/Solutions/SolutionsContent';
import { getElementCoordinates, getElementHeight } from '../Utility/Utilities';



const SolutionsContainer = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [isScrolling, setScrolling] = useState(false);
    // const [activeNav, setActiveNav] = useState(navBlocks["PLUGINS"]);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [pageHeight, setPageHeight] = useState();
    const [variableMargins, setVariableMargins] = useState({
        top: 0,
        bottom: 0
    });
    
    const content = "landingContainer"
    const scrolledRef = useRef(false);
    const { hash } = useLocation();

    // onMount hook. Detects if page has loaded, sets up event listeners, and sets states like windowHeight and pageHeight
    useEffect(() => {       
        const onPageLoad = () => {
                setLoading(false);
        }
         if(isLoading){
             if(document.readyState === 'complete'){
                 onPageLoad();
             } else {
                 window.addEventListener('load',onPageLoad, false);
                 return () => window.removeEventListener('load',onPageLoad);
             }
         } 
        if(!isLoading) {
            window.addEventListener('resize', (e) => {
                setWindowHeight(window.innerHeight);
                setWindowWidth(window.innerWidth);
            })
            // Due to using CSS Parallax, the y-axis scroll actually applies to the <Container id="mainContainer"> div rather than the entire page.
            // As such, scroll event listeners must be attached to it rather than document root for them to be activated by y-axis scrolling
            let containerElement = document.getElementById("solutionsContainer");
            containerElement.addEventListener('scroll', () => {
                setScrolling(true);
            })
            let divElement = document.getElementById(content);
            setPageHeight(divElement.offsetHeight)
        }
    }, [isLoading])

    // TODO - what about using the intersection API?
    // Hook that determines if any navPoints on the page are within the viewport, and populates the activeNav state accordingly. This may break 
    // if navpoints are too close to each other and overlap
    // useEffect(() => {
    //     if(!isLoading){
    //         navArray.forEach((item) => {
    //             //console.log(window.pageYOffset);
    //             if(((window.pageYOffset - 100) < getElementCoordinates(item).top) && ((window.pageYOffset + (windowHeight)/2) > getElementCoordinates(item).top)){
    //                 setActiveNav(item);
    //             };
    //         })
    //         setScrolling(false);
    //         //console.log("setScrollFalse")
    //     }
    // },[isLoading,isScrolling,windowHeight])

    // Hook updates margins for top and bottom elements based on current viewport height (so that those elements are centered when scrolled all the way up or down)
    useEffect(() => {
        setVariableMargins({
            top: (windowHeight - getElementHeight("topContainer")) / 2,
            bottom: (windowHeight - getElementHeight("bottomContainer")) / 2
        })
    }, [isLoading,windowHeight])

    // Hook to fix anchor scrolling bugs when traversing between pages caused by asynchronous rendering. 
    // From https://ericdcobb.medium.com/scrolling-to-an-anchor-in-react-when-your-elements-are-rendered-asynchronously-8c64f77b5f34
    useEffect(() => {
        if (hash && !scrolledRef.current) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                scrolledRef.current = true;
            }
        }
    });
    
    if(!isLoading){
        return(
            <>
                <Navbar windowWidth={windowWidth} />
                <Container id="solutionsContainer">
                    <DotContainer pageHeight={pageHeight} windowWidth={windowWidth} fewerDots={true}/>
                    <SolutionsContent isLoading={isLoading} variableMargins={variableMargins} windowHeight={windowHeight} windowWidth={windowWidth} />
                </Container>
            </>
    )}
    else {return(<></>)}
}

export default SolutionsContainer;

/* Container and non-content styles */

const Container = styled.div`
    position: absolute;
    margin:auto;
    height:100%;
    width:100%;
    left:0;
    scroll-behavior:smooth;
    
    perspective: ${theme.perspective+"px"};
    perspective-origin: 0 0;

    background-color:transparent;
    overflow-x:hidden;
    display:flex;
    -webkit-overflow-scrolling: touch; // iOS scroll fix
`