import styled from "styled-components";
import { Device } from "../../Device/Device";
import { HeadingH2 } from "components/Utility/HeaderStyles";
import { DottedList, DottedListContainer, DottedListItem } from "components/Utility/DottedContent";
import IMGProductPreview from "../img/catecut-shopper-preview.png";
import { StyledImage} from "components/Utility/StackedImage";

export const PluginsBlock = () => {
    return(
        <>
        <Spacer/>
        <HeadingH2>AI-powered styling solution for</HeadingH2>
        <HeadingH2>Online Retail</HeadingH2>
        <StyledRow>
            <StyledLeftColumn>
                <DottedListContainer>
                    <DottedList>
                        <PluginDottedListItem dotColor="rgba(232, 133, 165, 1)">
                            <DottedListPoint>Built by wardrobe stylists and online retail professionals</DottedListPoint>
                        </PluginDottedListItem>
                        <PluginDottedListItem dotColor="rgba(138, 189, 108, 1)">
                            <DottedListPoint>Matches clothing to suit each shopper's body structure</DottedListPoint>
                        </PluginDottedListItem>
                        <PluginDottedListItem dotColor="rgba(255, 209, 21, 1)">
                            <DottedListPoint>Embeds directly in your online store for tailored shopping</DottedListPoint>
                        </PluginDottedListItem>
                        <PluginDottedListItem dotColor="rgba(202, 160, 236, 1)">
                            <DottedListPoint>Inspires buyer confidence and loyalty</DottedListPoint>
                        </PluginDottedListItem>
                        <PluginDottedListItem dotColor="rgba(57, 198, 181, 1)">
                            <DottedListPoint>Improves inventory management and trend data</DottedListPoint>
                        </PluginDottedListItem>
                        <PluginDottedListItem dotColor="rgba(255, 106, 106, 1)">
                            <DottedListPoint>Reduces apparel returns, mitigating costs, CO2 and garment waste</DottedListPoint>
                        </PluginDottedListItem>
                    </DottedList>
                </DottedListContainer>
            </StyledLeftColumn>
            <StyledRightColumn>
                <ImageContainer>
                    <StyledImage src={IMGProductPreview} borderColor="rgba(15, 165, 155, 1)" />
                </ImageContainer>
            </StyledRightColumn>
        </StyledRow>
        </>
    )
}

const ImageContainer = styled.div`
    box-sizing: content-box;
    width: 700px;

    @media ${Device.mobile} {
        width: 95vw;
        height: 250px;
    }
`

const StyledRow = styled.div`
    margin-top: 40px;
    position:relative;
    display:flex;
    flex-direction:row-reverse;
    align-items:center;
    @media ${Device.mobile} {
        flex-direction: column;
        gap: 20px;
    }
`

const StyledLeftColumn = styled.div`
    position:relative;
    flex-direction: column;
    align-items: flex-start, center;
    text-align:center;
    gap:16px;
    max-width:100%;
    margin-left: 0px;
    margin-right: 20px;

    @media ${Device.mobile} {
        margin-right: 0px;
    }
`

const StyledRightColumn = styled(StyledLeftColumn)`
    margin-left: 20px;
    margin-right: 0px;

    @media ${Device.mobile} {
        margin-left: 0px;
    }
`


const PluginDottedListItem = styled(DottedListItem)`
    margin: 30px;
`

const DottedListPoint = styled.p`
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26.4px;

    margin:auto;
    color: #000000;
    @media ${Device.mobile} {
        font-size: 22px;
        line-height: 120%;
    }
`

const Spacer = styled.div`
    margin-bottom: 120px;

    @media ${Device.mobile} {
        margin-top: 180px;
    }
`