import {React, useState} from 'react'
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Device } from '../Device/Device';
import { LOGOCatecutSVG } from '../Logo/Logo';
import { MenuContainer, HamburgerIcon } from './HamburgerMenu';
import { navBlocks } from '../Solutions/SolutionsContent';

import IMGbackground from "../../../img/catecut-grainitexture.jpg"


const Navbar = (props) => {
    const [isExpanded, setExpanding] = useState(false);

    const handleExpand = (e) => {
        e.stopPropagation();
        setExpanding(!isExpanded);
    }

    // Legal type is used for Terms, Privacy pages, and Support
    if(props.page === "legal"){
        return(
            <Nav legal={true}>
                <Bar>
                    <BarLink to="/" > &lt; Back </BarLink>
                </Bar>
            </Nav>
        )
    } else {
        if(props.windowWidth < 600){
            return(
                <Nav>
                    <Logo reloadDocument to="/" onClick={() => setExpanding(false)}><LOGOCatecutSVG size='mobile' /></Logo>
                    <NavColumn>
                        <ContactButton to="/sales">Contact Sales</ContactButton>
                        <HamburgerIcon expand={isExpanded} handleClick={(e) => handleExpand(e)} />
                    </NavColumn>
                    <MenuContainer handleExpand={(e) => handleExpand(e)} expand={isExpanded} />
                </Nav>
            )
        } else {
            return(
                <Nav>
                    <Logo reloadDocument to="/" onClick={() => setExpanding(false)}><LOGOCatecutSVG /></Logo>
                    <NavLinkGroup>
                        <BarLink to="/" isCurrent={window.location.pathname === "/"}>Home</BarLink>
                        <BarLink to="/solutions" isCurrent={window.location.pathname === "/solutions" || window.location.pathname === "/marketplace"}>Solutions</BarLink>
                        <BarLink to="/support" isCurrent={window.location.pathname === "/support"}>Support</BarLink >
                    </NavLinkGroup>
                    <NavColumn>
                        <ContactButton to="/sales">Contact Sales</ContactButton>
                    </NavColumn>
                </Nav>
            )
        }
    }
}

export default Navbar;

const NavColumn = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    gap: 16px;
`

const NavLinkGroup = styled(NavColumn)`
    gap: 40px;
    margin-top: 28px;
`

const Nav = styled.div`
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: start;
    top:0;
    left:0;
    height: ${props => props.legal ? "30px" : "119px"};
    width:100%;

    background: url(${IMGbackground});
    background-attachment: fixed;

    padding-right:32px;
    z-index:10;
    @media ${Device.mobile} {
        height: 82px;
        padding-right:16px;
    }
`

const Bar = styled.div`
    position:fixed;
    display: flex;
    background-color: transparent;
    top:0px;
    left:0px;
    width:100%;
    height:50px;
    @media ${Device.mobile} {
        height:30px;
        justify-content:center;
        align-items:center;
        background-color: white;
        border-bottom: 1px solid black;
    }
`

const CommonLink = styled(Link)`
    position:relative;
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    line-height: 160%;
    text-decoration: none;
    color: rgba(0, 0, 0, 1);
`

const BarLink = styled(CommonLink)`
    color: ${props => props.isCurrent ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 1)"};
    ::before {
        background: ${props => props.isCurrent ? "rgba(103, 138, 191, 1)" : "transparent"};
        position: absolute;
        top: 23px;
        left: 50%;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        content: " ";
    }
`
BarLink.defaultProps = {
    isCurrent: false,
}

const SubMenuLink = styled(CommonLink)`
    padding: 12px 32px 12px 32px;
`

const BarSubMenu = styled.div`
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    visibility: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
`

const BarMenu = styled(BarLink)`
    ::after {
        padding-left: 7px;
        padding-right: 7px;
        content: url('data:image/svg+xml;charset=UTF-8,<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.832958 0.775756L14.667 0.775757C14.9111 0.775757 15.053 1.05157 14.9112 1.25013L7.99412 10.934C7.87447 11.1015 7.62553 11.1015 7.50588 10.934L0.588838 1.25013C0.447008 1.05157 0.588946 0.775756 0.832958 0.775756Z" fill="black"/></svg>');
        display: inline-block;
        transition: transform 200ms ease-in;
    }
    :hover::after {
        transform: rotate(180deg);
    }
    :hover ${BarSubMenu} {
        visibility: visible;
    }
`


const Logo = styled(Link)`
    position: relative;
    // top:-50px;
    //align-items: center;

    //color: #000000;

    z-index:11;
`

const LogoSimple = styled.a`
    position: relative;
    // top:-50px;
    //align-items: center;

    //color: #000000;

    z-index:11;
`

const ContactButton = styled(Link)`
    position:relative;
    display:flex;
    align-items: center;
    justify-content:center;
    margin:0;
    padding: 16px 8px;

    width: 176px;
    height: 50px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: none;
    text-align: center;
    letter-spacing: 0.2px;
    color:white;

    border-radius: 30px;
    border: 1.5px solid black;
    background-color: black;

    z-index:11;

    &:hover{
        background-color: white;
        color:black;
    }
    @media ${Device.mobile} {
    }
`
const SignupSimple = styled.a`
    top:0px;
    right:48px;
    position:relative;
    display:flex;
    align-items: center;
    justify-content:center;

    width: 243px;
    height: 50px;
    background: #FFFFFF;
    border: 1.5px solid #000000;
    border-radius: 30px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: none;
    text-align: center;
    letter-spacing: 0.2px;
    color: #000000;

    &:hover{
        color:#FFFFFF;
        background-color:#000000;
    }

    @media ${Device.mobile} {
        width: 125px;
        top: -15px;
        right: 15px;
    }
`