import styled from "styled-components"
import { useState } from "react"

import { capitalizeString } from "../../Utility/Utilities"

import { HeadingH5 } from "../../Utility/HeaderStyles"
import { InterButton } from "../Landing/LandingStyles"

import ArrowDB from "./img/down-arrow-black.svg"
import ArrowDG from "./img/down-arrow-grey.svg"
import ArrowDW from "./img/down-arrow-white.svg"
import CloseButton from "./img/button-close.svg"

export const Dropdown = (props) => {
    const [isExpanded, setExpanded] = useState(false)

    const handleHeaderOnClick = () => {
        setExpanded(!isExpanded)
    }

    const handleOptionClick = (e) => {
        setExpanded(!isExpanded)
        props.selectOnChangeHandler(e)
    }

    return(
        <DropdownContainer>
            <DropHeader onClick={handleHeaderOnClick}>{props.title}</DropHeader>
            <OptionContainer expand={isExpanded}>
                {props.types.map((item, index) => {
                    return <DropOption 
                        onClick={(e) => handleOptionClick(e)} 
                        key={index} 
                        id={item.type}
                        selected={props.selected.type === item.type}
                    > 
                        {capitalizeString(item.title)} 
                    </DropOption>
                })}
            </OptionContainer>
        </DropdownContainer>
    )
}

const DropInter = styled(InterButton)`
    color: ${props => props.light ? 'black' : 'white'};
    &:hover{
        color: ${props => props.light ? "white" : "black" };
        background-color: ${props => props.light ? "black" : "white" };
        border: 1.5px solid black;
    }
`

const DropdownContainer = styled.div`
    position: relative;
    z-index:2;
    width: 190px;
`
const OptionContainer = styled.div`
    position: absolute;
    display: ${props => props.expand ? "flex" : "none"};
    flex-direction: column;
    padding: 16px 0px;
    width: 100%;
    margin: 0;

    overflow: hidden;
    background-color: white;

    border: 1.5px solid black;
    border-radius: 16px;
`


const DropHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    padding: 16px 32px 16px 40px;
    gap: 8px;
    
    height: 54px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.2px;

    border-radius: 30px;
    border: ${props => props.light ? "1.5px solid black" : "" };

    color: ${props => props.light ? "black" : "white" };
    background-color: ${props => props.light ? "white" : "black" };
    
    &:after{
        content: url(${props => props.light ? ArrowDB : ArrowDW });
    }

    &:hover{
        color: ${props => props.light ? "white" : "black" };
        background-color: ${props => props.light ? "black" : "white" };
        border: ${props => props.light ? "" : "1.5px solid black" };
        &:after{
            content: url(${props => props.light ? ArrowDW : ArrowDB });
        }
    }
`
const DropOption = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    height: 54px;
    padding: 16px 8px 16px 8px;
    gap: 8px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.2px;

    color: ${props => props.selected ? "white" : "black" };
    background-color: ${props => props.selected ? "black" : "white" };

    // &:after{
    //     position:absolute;
    //     margin:0;
    //     right:10px;
    //     opacity:0.8;
    //     content: url(${props => props.selected ? CloseButton : ''});
    // }

    &:hover{
        color: white;
        background-color: black;
    }
`

const DropCloseButton = styled.div`

`

const DropHorizontalClose = styled.div`
    position: absolute;
    left: 6.41%;
    right: 6.41%;
    top: 45.9%;
    bottom: 45.9%;
    background: #000000;
    border-radius: 6px;

    transform: ${props => props.expand ? "rotate(-45deg)" : "rotate(-90deg)"};
    transition: transform 200ms ease-in;
`
const DropVerticalClose = styled.div`
    position: absolute;
    left: 6.41%;
    right: 6.41%;
    top: 45.9%;
    bottom: 45.9%;
    background: #000000;
    border-radius: 6px;

    transform: ${props => props.expand ? "rotate(45deg)" : "rotate(0deg)"};
    transition: transform 200ms ease-in;
`