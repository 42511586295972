import styled from "styled-components";
import { Device } from "../ui/Device/Device";

export const Container = styled.div`
    position:absolute;
    top:0;
    left:0;
    background:transparent;
`
export const LegalContainer = styled.div`
    position:relative;
    margin: auto 75px 85px 75px;
    top:50px;
    left:0;
    background:transparent;
    text-align: justify;
    text-justify: inter-word;
    @media ${Device.mobile} {
        top:30px;
        margin: auto 10px 85px 10px;
    }
`

export const UList = styled.ul`
`

export const ListItem = styled.li`
    list-style: none;
`