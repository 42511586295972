import styled from "styled-components";
import { Device } from "components/ui/Device/Device";

import { HeadingH2, PzMedium, PzSmall } from "../../../Utility/HeaderStyles";
import { StyledGridRow } from "../../../Utility/LayoutStyled";
import { PrimaryButton } from "../../Buttons/Buttons";

export const ClothingBlock = () => {
    return (
        <>
        <HeadingH2>Unlock Your Customers'</HeadingH2>
        <MoreResponsivePZSmall>Best New Wardrobe</MoreResponsivePZSmall>
        <HeadingH2>while drastically reducing apparel returns</HeadingH2>
        <StyledGridRow>
            <PrimaryButton padding='16px 8px' width="270px" href="/sales">Book a demo</PrimaryButton>
        </StyledGridRow>
        </>
    )
}

const MoreResponsivePZSmall = styled(PzSmall)`
    font-size: 60px;

    @media ${Device.mobile} {
        font-size: 36px;
    }
`