import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { PrimaryButton } from "../ui/Buttons/Buttons";
import { invokeLambdaFunction } from "../Utility/invokeLambdaFunction";
import { ContactForm } from "../ui/Contact/ContactForm";
import { ContactCard } from "../ui/Contact/ContactCard";
import { StyledGridRow } from "../Utility/LayoutStyled";
import { Device } from "../ui/Device/Device";

import { Dropdown } from "../ui/Buttons/Dropdown";

import { SubtitleStyle } from "../Utility/TextStyles";
import Footer from "../Footers/Footer";
import Navbar from "../ui/Nav/Navbar";

export const contactTypes = [
    { 
        type:"brand", 
        title:"As a fashion brand"
    },
    { 
        type:"journalist", 
        title:"As a journalist"
    },
    { 
        type:"stylist", 
        title:"As a fellow stylist"
    },
    { 
        type:"friend", 
        title:"Only as a friend"
    }
]

export const findContactByType = (type) => {
    return contactTypes.find(item => item.type == type)
}

export const ContactContainer = () => {
    const [selected, setSelected] = useState({ type: "friend", title: "Only as a friend" })
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(()=>{
        window.addEventListener('resize', (e) => {
            setWindowHeight(window.innerHeight);
            setWindowWidth(window.innerWidth);
        })
    })
    

    const selectOnChangeHandler = (e) => {
        setSelected(findContactByType(e.target.id))
    }



    return(
        <PageContainer>
            <Navbar windowWidth={windowWidth}/>
            <ContentContainer>
                <ContactCard 
                    selectOnChangeHandler={selectOnChangeHandler}
                    types={contactTypes}
                    selected={selected}
                    />
            </ContentContainer>
            <Footer />
        </PageContainer>
    )
}

const PageContainer = styled.div`
position:absolute;
width:100%;
top:0px;
left:0px;
margin: auto;
align-items: top;
`

const ContentContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin: 180px 0 80px 0;
width:100%;
`
