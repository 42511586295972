import React, {useEffect} from "react"
import styled from "styled-components"

import Footer from '../../Footers/Footer';

import { IMGCarrier, IMGCat, IMGRetail, IMGRobot } from './LandingImages';

import { HeadingH1, HeadingH2, PzLarge, PzMedium, PzSmall } from "../../Utility/HeaderStyles";

import { ParagraphLarge, ParagraphMedium, EyebrowStyle} from "../../Utility/TextStyles";
import { PrimaryButton } from "../Buttons/Buttons";

import { StyledGridCol, StyledGridRow } from "../../Utility/LayoutStyled";

import { Device } from '../Device/Device';
import { Collapsible } from "../Buttons/Collapsible";
import { TeamContainer } from "./TeamCards/TeamContainer";

import { QuickPeekContainer } from "./QuickPeek/QuickPeekContainer";
import { DownloadBlock, BugginBlock, RightOutfitBlock } from "./Download/DownloadBlock";
import { RandomLine } from "../../Utility/RandomLine";
import { FancyPantsLines, DoYouWantToShineLines } from "./LandingLines";
import { FAQBlock } from "./FAQ/FAQBlock";

export const navArray = [
    "DoYouWantToShine",
    "FancyPants",
    "Download",
    "QuickPeek",
    "FAQ",
    "CatecutExplained",
    "TheEnd"
];

export const LandingContent = (props) => {
    return(
        <Landing id="landingContainer">
            <ContentBlock id="topContainer" marginTop={props.variableMargins["top"]}>
                <NavAnchor id="DoYouWantToShine" offset={props.windowHeight / 2} />
                <HeadingH1>Do you want to</HeadingH1>
                <PzLarge>shine</PzLarge>
                <HeadingH1>with your style?</HeadingH1>
                <PrimaryButton margin='80px auto auto auto' mMargin='40px auto auto auto' padding='16px 4px' width='350px' mWidth='303px' mFontSize='20px' href="#Download">
                    <RandomLine lineList={DoYouWantToShineLines} />
                </PrimaryButton>
            </ContentBlock>

            <ContentBlock>
                <NavAnchor id="FancyPants" offset={props.windowHeight / 2}/>
                <HeadingH2>
                    <RandomLine lineList={FancyPantsLines} />
                </HeadingH2>
            </ContentBlock>

            {/*<ContentBlock>
                <RightOutfitBlock />
            </ContentBlock>*/}

            <ContentBlock>
                <NavAnchor id="Download" offset={props.windowHeight / 2}/>
                <DownloadBlock windowWidth={props.windowWidth}/>
            </ContentBlock>

            {/*<ContentBlock>
                <BugginBlock />
            </ContentBlock>*/}

            
            <ContentBlock>
                <NavAnchor id="QuickPeek" offset={props.windowHeight / 2}/>
                <QuickPeekContainer />
            </ContentBlock>

            <ContentBlock>
            <NavAnchor id="Ready" offset={props.windowHeight / 2}/>
                <HeadingH2>Are you</HeadingH2>
                <PzMedium>ready</PzMedium>
                <HeadingH2>to be part of this?</HeadingH2>
                <StyledGridRow>
                    <PrimaryButton padding='16px 8px' light='true' width="270px" href="#CatecutExplained">I want to know more</PrimaryButton>
                    <PrimaryButton padding='16px 8px' width="270px" href="#Download">OMG yeeeaah!</PrimaryButton>
                </StyledGridRow>
            </ContentBlock>

            <ContentBlock>
            <NavAnchor id="FAQ" offset={props.windowHeight / 2}/>
                <FAQBlock />
            </ContentBlock>

            <ContentBlock display='none'>
                <EyebrowStyle>The team</EyebrowStyle>
                <HeadingH2>Nice to meet you! My name is...</HeadingH2>
                <TeamContainer />
            </ContentBlock>

            <ContentBlock>
            <NavAnchor id="CatecutExplained" offset={props.windowHeight / 2}/>
                <HeadingH2>
                    This is Catecut, explained in 4 points:
                </HeadingH2>
            </ContentBlock>

            <ContentBlock marginTop="400">
                <ImageContainer 
                    windowWidth={props.windowWidth}
                    leftOffset="125"
                    mLeftOffset="35vw"
                    topOffset="-150"
                    mTopOffset="-220"
                >
                    <IMGCarrier />
                </ImageContainer>
                <BulletPoint>1</BulletPoint>
                <HeadingH2>Clothes have to fit</HeadingH2>
                <PzMedium>
                        YOU
                </PzMedium>
                <LandingParagraph>We are all different people in different stages of our lives; the clothes you will get will be based on your unique hot bod</LandingParagraph>
            </ContentBlock>

            <ContentBlock>
                <ImageContainer 
                    windowWidth={-props.windowWidth}
                    leftOffset="-140"
                    mLeftOffset="-35vw"
                    topOffset="-150"
                    mTopOffset="-170"
                >
                    <IMGRobot />
                </ImageContainer>
                <BulletPoint>2</BulletPoint>
                <HeadingH2>Basically, the most</HeadingH2>
                <PzLarge>
                        chic
                </PzLarge>
                <HeadingH2>artificial intelligence</HeadingH2>
                <LandingParagraph>Our professional stylists have been teaching our AI how to stylize for years</LandingParagraph>
            </ContentBlock>

            <ContentBlock>
                <ImageContainer 
                    windowWidth={props.windowWidth}
                    leftOffset="160"
                    mLeftOffset="35vw"
                    topOffset="-145"
                    mTopOffset="-230"
                >
                    <IMGRetail />
                </ImageContainer>
            <BulletPoint>3</BulletPoint>
                <HeadingH2>We find</HeadingH2>
                <PzMedium>killer</PzMedium>
                <HeadingH2>clothes just for you</HeadingH2>
                <LandingParagraph>Your clothes belong on you and not in the ocean</LandingParagraph>
            </ContentBlock>

            <ContentBlock>
                <ImageContainer 
                    windowWidth={-props.windowWidth}
                    leftOffset="-160"
                    mLeftOffset="-35vw"
                    topOffset="290"
                    mTopOffset="-170"
                >
                    <IMGCat />
                </ImageContainer>
                <BulletPoint>4</BulletPoint>
                <HeadingH2>A</HeadingH2>
                {props.windowWidth > 600 
                    ? <PzMedium>sparkling</PzMedium> 
                    : <PzSmall>sparkling</PzSmall>
                }
                <HeadingH2>color palette</HeadingH2>
                <LandingParagraph>A personalized color palette to finally know what colors suit you best</LandingParagraph>
            </ContentBlock>
            
            <ContentBlock id="bottomContainer" marginBottom={props.variableMargins["bottom"]}>
                <NavAnchor id="TheEnd" offset={props.windowHeight / 2}/>
                <Footer type="short"/>
            </ContentBlock>

        </Landing>
    )
}

const Landing = styled.div`
    position:absolute;
    width: 100%;
    height:auto;
    top:0px;
    left:0px;
    margin: auto;
    align-items: top;
    overflow: hidden;
    background:transparent;
`

const LandingParagraph = styled(ParagraphLarge)`
@media ${Device.mobile} {
    font-size: 18px;
    width:350px;
}
`

const ContentBlock = styled.div`
    padding-left: ${props => props.noPadding === true ? "auto" : "5vw" };
    padding-right: ${props => props.noPadding === true ? "auto" : "5vw" };
    margin-top: ${props => props.marginTop ? props.marginTop+"px" : "auto"};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom+"px" : "300px"};
    display: ${props => props.display ? props.display : "flex"};
    flex-direction: column;
    align-items: center;
    position: relative;

    @media ${Device.mobile} {
        min-width:350px;
        width: 90vw;
        margin-left:auto;
        margin-right:auto;
        margin-top: ${props => props.marginTop ? (props.marginTop * 0.75)+"px" : "auto" };
        margin-bottom: ${props => props.marginBottom ? props.marginBottom+"px" : "225px"};
    }
`

const NavAnchor = styled.div`
    position: absolute; 
    bottom: calc(50% - 10px + ${props => props.offset+"px"});
`

const BulletPoint = styled.div`
    background-color: black;
    color: white;
    border-radius: 100px;
    height: 80px;
    width: 80px;
    display: flex;
    margin: auto auto 35px auto;
    justify-content: center;
    align-items: center;
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    z-index:1;

    @media ${Device.mobile} {
        height: 62px;
        width: 62px;
    }
`

/** img */

const ImageContainer = styled.div`
    display:none;
    position: relative;
    background-color: transparent;
    height:0px;
    z-index:-1;
    left: ${props => props.leftOffset ? (props.windowWidth / 2) - props.leftOffset+"px" : "none"};
    right: ${props => props.rightOffset ? (props.windowWidth / 2) - props.rightOffset+"px" : "none"};
    top: ${props => props.topOffset ? props.topOffset+"px" : "none"};
    bottom: ${props => props.bottomOffset ? props.bottomOffset+"px" : "none"};
    
    @media ${Device.mobile} {
        left: ${props => props.mLeftOffset ? props.mLeftOffset : "none"};
        right: ${props => props.mRightOffset ? props.mRightOffset : "none"};
        top: ${props => props.mTopOffset ? props.mTopOffset+"px" : "none"};
        bottom: ${props => props.mBottomOffset ? props.mBottomOffset+"px" : "none"};
    }
`
/* Deprecated */

const LandingButton = styled.a`
    margin: 80px auto auto auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding:16px 24px;
    gap: 16px;
    color: ${props => props.light ? "black" : "white" };
    margin:auto;
    width: ${props => props.width ? props.width : "350px" };
    height: 60px;

    @media ${Device.mobile} {
        margin: auto auto auto auto;
    }

    
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    
    background: ${props => props.light ? "white" : "black" };
    border: 1.5px solid black;
    border-radius: 30px;

    /* identical to box height, or 29px */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;
    text-decoration:none;

    &:hover{
        color: ${props => props.light ? "white" : "black" };
        background-color: ${props => props.light ? "black" : "white" };
        border: 1.5px solid black;
    }
`
