import React from "react";
import styled from "styled-components";
import { theme } from "../../../theme";

/*
This implementation is based on the article "Performant Parallax" posted by Paul Lewis and "flackr" in 2016.
(https://developer.chrome.com/blog/performant-parallaxing/)

If a dot is allowed to go too far down the page it will expand the whole thing, so some care must be shown
"Left" and "top" props are multiplied in the styled components themselves to get rough parity in placement 
between sizes (due to perspective etc).

Both can be considered to correspond to a percentage of viewport width and percentage of page height 
("pageMultiplier" is a value derived from the total page height, divided by 100, and passed into the dot 
elements as a prop), respectively. E.g. left="100" will place an element at the right edge of the viewport,
while top="50" will place it at around the halfway point of the page horizontally.

The lowest dots are disabled on mobile (viewport width < 600) to avoid stretching the page down on Chrome.

TODO - Since the refactoring of the landing page to prioritise B2B over B2C / the app, we wanted fewer dots
on the B2B / main page. It it dealt with by simply return a less numerous set of dots, but maybe we can think
of a sort of function that produces a random positioned set of dots according to wished amount, if we have time
to spare. 
*/ 

const DotContainer = (props) => {
    const pageMultiplier = props.pageHeight / 100    
    if (props.fewerDots) {
        return(
            <>
                <Biggest    left="75"   top="8"     dotColor="#E4C5FF"  pageMultiplier={pageMultiplier}/>
                <Big        left="30"   top="31"    dotColor="#C7FFE9"  pageMultiplier={pageMultiplier}/>
                <Big        left="60"   top="41"    dotColor="#FFEDA7"  pageMultiplier={pageMultiplier}/>
                <Medium     left="92"   top="55"    dotColor="#FFABAB"  pageMultiplier={pageMultiplier}/>
                <Big        left="50"   top="75"    dotColor="#DDF5FF"  pageMultiplier={pageMultiplier}/>
                <Medium     left="12"   top="65"    dotColor="#D8FAC9"  pageMultiplier={pageMultiplier}/>
                <Big        left="43"   top="92"    dotColor="#E4C5FF"  pageMultiplier={pageMultiplier}/>
                <Medium     left="58"   top="97"    dotColor="#FFE7EF"  pageMultiplier={pageMultiplier}/>
            </>
        )
    }

    return(
            <>
                <Biggest left="50" top="7"  dotColor="#FFF6A8" pageMultiplier={pageMultiplier}/>
                <Biggest left="40" top="29" dotColor="#CCF5E4" pageMultiplier={pageMultiplier}/>
                <Biggest left="51" top="53" dotColor="#A7E6A4" pageMultiplier={pageMultiplier}/>
                <Biggest left="40" top="70" dotColor="#FFDD9A" pageMultiplier={pageMultiplier}/>

                <Medium left="10.8" top="8.4"   dotColor="#257E79" pageMultiplier={pageMultiplier}/>
                <Medium left="72.0" top="28.1"  dotColor="#EA5555" pageMultiplier={pageMultiplier}/>
                <Medium left="63.3" top="76.27" dotColor="#B89723" pageMultiplier={pageMultiplier}/>

                <Small left="29.3" top="36.4" dotColor="#BDD28B" pageMultiplier={pageMultiplier}/>
                <Small left="90.4" top="86.5" dotColor="#874529" pageMultiplier={pageMultiplier}/>
                <Small left="7.9"  top="54.6" dotColor="#8DFFF1" pageMultiplier={pageMultiplier}/>
                <Small left="29.3" top="2.9"  dotColor="#E94B97" pageMultiplier={pageMultiplier}/>

                {props.windowWidth > 600 ? <>
                    <Big    left="75"   top="25"    dotColor= "#FFBA7B" pageMultiplier={pageMultiplier}/>
                    <Big    left="85"   top="42"    dotColor= "#C9D3DF" pageMultiplier={pageMultiplier}/>
                    <Big    left="15"   top="75"    dotColor= "#0BA266" pageMultiplier={pageMultiplier}/>
                    <Big    left="55"   top="95"    dotColor= "#FFDFF8" pageMultiplier={pageMultiplier}/>
                    <Medium left="1.7"  top="91.9"  dotColor="#F1EBE8"  pageMultiplier={pageMultiplier}/>
                    <Small  left="66.1" top="106.7" dotColor="#50A99A"  pageMultiplier={pageMultiplier}/>
                </> : <></> }

            </>
    )

}

const ParallaxScale = (distance) => {
    return ((theme.perspective - distance) / theme.perspective)
}

// base style for the background dots
const BackgroundDot = styled.div`
    position:absolute;
    width: 192px;
    height: 192px;
    border-radius: 100%;
    transform-origin: 0 0;
    margin:auto;
    padding:auto;
`

/* 192 px */
const Biggest = styled(BackgroundDot)`
    background: ${props => props.dotColor ? props.dotColor : "grey"};
    left: ${props => props.left * 2+ "vw"};
    top: ${props => props.top * (props.pageMultiplier * 0.85) +"px"};
    transform:translateZ(-2px) scale(${ParallaxScale(-2)});
    z-index:-2;
`

/* 72px */
const Big = styled(BackgroundDot)`
    background: ${props => props.dotColor ? props.dotColor : "grey"};
    left: ${props => props.left * 3 + "vw"};
    top: ${props => props.top * (props.pageMultiplier * 0.90) + "px"};
    transform:translateZ(-4px) scale(${ParallaxScale(-4)});
    height:72px;
    width:72px;
    z-index:-3;
`

/* 48px */
const Medium = styled(BackgroundDot)`
    background: ${props => props.dotColor ? props.dotColor : "grey"};
    left: ${props => props.left * 4 + "vw"};
    top: ${props => props.top * (props.pageMultiplier * 1) + "px"};
    transform:translateZ(-6px) scale(${ParallaxScale(-6)});
    height:48px;
    width:48px;
    z-index:-4;
`

/* 24px */
const Small = styled(BackgroundDot)`
    background: ${props => props.dotColor ? props.dotColor : "grey"};
    left: ${props => props.left * 5 + "vw"};
    top: ${props => props.top * 80 + "px"};
    height: 24px;
    width: 24px;
    transform:translateZ(-8px) scale(${ParallaxScale(-8)});
    z-index:-5;
`
export default DotContainer;