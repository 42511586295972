export const capitalizeString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// Returns co-ordinates of element (by id) relative to <body>
export const getElementCoordinates = (targetId) => { 
    let elem = document.getElementById(targetId);
    let rect = elem.getBoundingClientRect();

    return{
        left: rect.left + window.scrollX - document.body.getBoundingClientRect().top,
        top: rect.top + window.scrollY - document.body.getBoundingClientRect().left
    };
}

// Returns height of a given element (by id)
export const getElementHeight = (targetId) => {
    let divElement = document.getElementById(targetId);
    if (divElement){ 
        return divElement.offsetHeight; 
    } else { 
        return 0 
    }
}