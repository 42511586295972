export const Size = {
    mobile: '599',
    tablet: '1239',
    laptop: '1439',
    desktop: '1919',
    desktopPlus: '1920'

}

export const Device = {
    mobile: `(max-width: ${Size.mobile+'px'})`,
    tablet: `(max-width: ${Size.tablet+'px'})`,
    laptop: `(max-width: ${Size.laptop+'px'})`,
    desktop: `(max-width: ${Size.desktop+'px'})`,
    desktopPlus: `(min-width: ${Size.desktopPlus+'px'})`
}