export const theme = {
    // Colours
    primaryDark: '#000000',
    primaryLight: '#F5F5F5',
    primaryHover: '#343078',
    elementMain: '#E6E6E6',
    elementDarker: '#B3B3B3',
    elementDisabled: '#E8E8E8',
    elementWarning: '#CE4020',
    imageBackground: '#808080',
    UIBlack60: '#4E4E4E',

    // Font
    fontWeightRegular: '500pt',

    // Sizes
    mobile: '576px',

    // Parallax perspective value. Must be consistent between DotContainer and LandingContent for proper functionality
    perspective: 2,
}