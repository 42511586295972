import React from "react";
import styled from "styled-components";
import { HeadingH2, PzMedium, PzSmall } from "../../../Utility/HeaderStyles";
import { Device } from "../../Device/Device";
import { IMGLanding1, IMGLanding2, IMGLanding3 } from "../LandingImages";
import { PrimaryButton } from "../../Buttons/Buttons";
import { StyledGridCol } from "../../../Utility/LayoutStyled";
import { AppleDownload, GoogleDownload } from "../../../Utility/ExternalLinks";
import { RandomLine } from "../../../Utility/RandomLine";
import { BugginLines, RightOutfitLines } from "../LandingLines";

export const RightOutfitBlock = () => {
    return(
            <Container>
                <LeftColumn>
                    <IMGLanding1/>
                </LeftColumn>
                <RightColumn>
                    <TextRow>
                        <HeadingH2 width='600px' mWidth='350px'>
                            <RandomLine lineList={RightOutfitLines} />
                        </HeadingH2>
                    </TextRow>
                    <ImageRow>
                        <IMGLanding2 />
                    </ImageRow>
                </RightColumn>
            </Container>
    )
}

export const DownloadBlock = (props) => {
    return(
        <>
            <HeadingH2>You are a</HeadingH2>
            {props.windowWidth > 600
                ? <PzMedium>diamond</PzMedium>
                : <PzSmall>diamond</PzSmall>
            }
            <HeadingH2>in the rough, time to shine!</HeadingH2>
            <StyledGridCol>
                <PrimaryButton padding='16px 8px' width="270px" href={AppleDownload}>Download for iPhone</PrimaryButton>
                <PrimaryButton padding='16px 8px' width="270px" href={GoogleDownload}>Download for Android</PrimaryButton>
            </StyledGridCol>
        </>
    )
}

export const BugginBlock = () => {
    return(
        <Container>
            <LeftColumn>
                <HeadingH2 width='600px' mWidth='350px'>
                    <RandomLine lineList={BugginLines} />
                </HeadingH2>
            </LeftColumn>
            <RightColumn>
                <IMGLanding3 />
            </RightColumn>
        </Container>
    )
}


const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0px;
    width:100%;
`
const RightColumn = styled.div`
    display: flex;
    flex: 1 5 auto;
    flex-direction: column;
    gap: 80px;

    word-wrap: break-word;
    white-space: normal;
`
const LeftColumn = styled.div`
    display: flex;
    flex: 1 3 auto;
    flex-direction: column;
    align-content: flex-end;
    gap: 80px;
`

const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    align-self: end;
    @media ${Device.mobile} {
        align-self:center;
    }
`
const TextRow = styled.div`
    padding-top:40px;
    display: flex;
    flex-direction: row;
    align-self:start;
    @media ${Device.mobile} {
        align-self:center;
    }
`

