import { createGlobalStyle } from 'styled-components';
import backgroundImage from './img/catecut-grainitexture.jpg'
import { Device } from './components/ui/Device/Device';

export const GlobalStyles = createGlobalStyle`
  html{
    font-size:8px;
    @media ${Device.mobile} {
      width: 350px;
    };
  }

  }
  html, body {
    margin: 0;
    padding: 0;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  body {
    align-items: center;
    background: primaryLight;
    background-image: url(${backgroundImage});
    background-attachment: fixed;
    color: primaryDark;
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    justify-content: center;
    text-rendering: optimizeLegibility;
    height: auto;
    /* height: -moz-available; */
    /* height: -webkit-fill-available;  */
    /* height: fill-available; */
  }
`;