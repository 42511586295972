// import { InvokeCommand, LambdaClient, LogType } from "@aws-sdk/client-lambda";
// import {fromIni} from '@aws-sdk/credential-providers';
import axios from "axios";

export const invokeLambdaFunction = async (functionName, payload) => {
    // const client = new LambdaClient({
    //     region:'eu-west-1', 
    //     credentials: fromIni({
    //         profile: 'default',
    //    })
    // });
    // const command = new InvokeCommand({
    //     FunctionName: functionName,
    //     Payload: JSON.stringify(payload)
    // });
    // const { Payload, LogResult } = await client.send(command);
    // const result = Buffer.from(Payload).toString();
    // const logs = Buffer.from(LogResult, "base64").toString();
    // return { logs, result };

    const config = {
        headers:{'content-type': 'application/json'}
    }
    return axios.post(process.env.REACT_APP_FORM_URL, JSON.stringify(payload), config).then(res => res.data)

};