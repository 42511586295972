import { useEffect, useState } from "react";
import styled from "styled-components"
import { HeadingH2, HeadingH5 } from "../Utility/HeaderStyles"
import { ParagraphMedium } from "../Utility/TextStyles";
import { Collapsible } from "../ui/Buttons/Collapsible";
import Footer from "../Footers/Footer";
import { StyledGridCol } from "../Utility/LayoutStyled";
import Navbar from "../ui/Nav/Navbar";
import { ContactForm } from "../ui/Contact/ContactForm";
import { invokeLambdaFunction } from "../Utility/invokeLambdaFunction";

export const SupportContainer = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [result, setResult] = useState();
    const [selected, setSelected] = useState({ type: "brand", title: "As a fashion brand" })
    const SupportAddress = process.env.REACT_APP_FORM_SUPPORT_ADDRESS

    useEffect(()=>{
        window.addEventListener('resize', (e) => {
            setWindowHeight(window.innerHeight);
            setWindowWidth(window.innerWidth);
        })
    })
    
    return(
        <Container>
            <Navbar windowWidth={windowWidth}/>
            <Content id="supportContainer" windowWidth={windowWidth}>
                    <HeadingH2>Support</HeadingH2>
                    <HeadingH5>For technical support, please use the form below: </HeadingH5>
                    <ContactForm formId='supportForm' selected= {{type:"support", title:"Support request"}} address='support' />
                <SupportSpacer />
                <Footer type="short"/>
            </Content>
        </Container>
    )
}
/*
<FAQContainer display='none'>
    <HeadingH5>FAQ</HeadingH5>
    <StyledGridCol>
        <Collapsible title="The app doesn't work properly on my watch/car/toaster!">
            <ParagraphMedium>Only Android and iOS mobile devices are currently supported.</ParagraphMedium>
        </Collapsible>
        <Collapsible title="">
            <ParagraphMedium>Unfortunately the early access version is only available for iOS devices. The full version will of course be available for both iOS and Android.</ParagraphMedium>
        </Collapsible>
        <Collapsible title='How do I sign up for the early access test?' >
            <ParagraphMedium>HERE IS SOME TEXT TO RENDER, YOU JACKANAPES </ParagraphMedium>
        </Collapsible>
    </StyledGridCol>
</FAQContainer>
*/
const SupportLink = styled.a`
    color:black;
    font-style:italic;
`

const SupportSpacer = styled.div`
    margin-bottom:80px;
`

const FAQContainer = styled.div`
    margin-top:80px;
    padding:0 16px 0 16px;
    display: ${props => props.display ? props.display : 'flex'};
    flex-direction: column;
    justify-content:center;
    align-items: center;
    position: relative;
    overflow: hidden;

    min-width:300px;
`

const Content = styled.div`
    position:absolute;
    width: 100%;
    height:auto;
    top:80px;
    left:0px;
    margin: auto;
    align-items: top;
    overflow: hidden;
    background:transparent;
`

const Container = styled.div`
    background-color:white;
`