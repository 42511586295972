import styled from "styled-components";
import { Device } from "../ui/Device/Device";

export const ButtonStyle = styled.div`
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.3px;
    color: #000000;
    margin:auto;
    @media ${Device.mobile} {
        width: 350px;
    }
` 
export const ButtonInterStyle = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: #000000;
    margin:auto;
    @media ${Device.mobile} {
        width: 350px;
    }
` 

export const EyebrowStyle = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: ${props => props.gray ? "#4E4E4E" : "#000000"};
    letter-spacing: ${props => props.allcaps ? "0.5px" : "" };
    text-transform: ${props => props.allcaps ? "uppercase" : "" };
    text-align:center;
    margin:auto;
    @media ${Device.mobile} {
        width: 350px;
    }
`

export const SubtitleStyle = styled.p`
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 160%;
    color: #000000;
    margin:auto;
    @media ${Device.mobile} {
        width: ${props => props.mWidth ? props.mWidth : ""};
    }
` 

export const ParagraphLarge = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${props => props.bold ? "700" : "400"};
    font-size: 24px;
    line-height: 160%;
    color: #000000;
    //margin: auto;
    width:572px;
    text-align:center;
` 

export const ParagraphMedium = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${props => props.bold ? "700" : "400"};
    font-size: 18px;
    line-height: 160%;
    color: #000000;
` 

export const ParagraphSmall = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${props => props.bold ? "700" : "400"};
    font-size: 16px;
    line-height: 160%;
    color: #000000;
` 