import styled from "styled-components";

import { HeadingH2, HeadingH4, HeadingH5, HeadingH6 } from "components/Utility/HeaderStyles";
import { ParagraphMedium } from "components/Utility/TextStyles";
import { Device } from "../../Device/Device";
import { StyledImage } from "components/Utility/StackedImage";
import IMGDeepTagging from "../img/catecut-deep-tagging-product.png";
import IMGFilterByAttributes from "../img/filter-by-attributes.png";
import { PrimaryButton } from "components/ui/Buttons/Buttons";

export const Step1Block = () => {
    return (
        <>
        <HeadingH2>How it works</HeadingH2>
        <MarginZHeadingH4>For online retailers</MarginZHeadingH4>
        <WidthStyledRow>
            <ParagraphContainer>
                <ParagraphMedium bold={true}>Efficiently implements with your store platform</ParagraphMedium>
                <ParagraphMedium>We handle implementation on our end, with your team's input, ensuring a hassle-free experience for you. Easily adaptable to the most common platforms.</ParagraphMedium>
            </ParagraphContainer>
            <ParagraphContainer>
                <ParagraphMedium bold={true}>Blends in with your store's branding</ParagraphMedium>
                {/* FIXME - we need linebreaks here and below because the header of the paragraph over wraps stupidly and pushes its text down out of alignment... This works but find a more elegant solution
                TODO - try tables instead ! */}
                <br/> 
                <ParagraphMedium>Catecut blends seamlessly into your store's brand and overall aesthetic.</ParagraphMedium>
            </ParagraphContainer>
            <ParagraphContainer>
                <ParagraphMedium bold={true}>Categorizes clothing from your product imagery</ParagraphMedium>
                <ParagraphMedium>Our AI automatically tags and classifies each garment's attributes, such as cut, fit and style, directly from your product images.</ParagraphMedium>
            </ParagraphContainer>
        </WidthStyledRow>
        <PrimaryButton padding='16px 8px' light='true' width="270px" href="/solutions">Show me more</PrimaryButton>
        <Spacer />
        <ImageContainer>
            <StyledImage src={IMGDeepTagging} borderColor="rgba(138, 189, 108, 1)" />
        </ImageContainer>
        </>
    )
}

export const Step2Block = () => {
    return (
        <>
        <MarginZHeadingH5>Your customers look and feel their best</MarginZHeadingH5>
        <WidthStyledRow>
            <ParagraphContainer>
                <ParagraphMedium bold={true}>Shoppers gain confidence</ParagraphMedium>
                <br/>
                <ParagraphMedium>When clothes don't fit quite right, shoppers lose confidence and become dissatisfied, increasing the incidence of cart abandonment and costly returns. Catecut's stylist recommendations consider the buyer's body structure matching the buyer to clothing with the best cuts and fits from your online store.</ParagraphMedium>
            </ParagraphContainer>
            <ParagraphContainer>
                <ParagraphMedium bold={true}>The shopping experience is tailored</ParagraphMedium>
                {/* FIXME - we need linebreaks here and below because the header of the paragraph over wraps stupidly and pushes its text down out of alignment... This works but find a more elegant solution
                TODO - try tables instead ! */}
                <br/> 
                <ParagraphMedium>We help you tailor the shopping experience to each unique customer visiting your online store, making the experience more accurate and enjoyable. We curate results based on the shopper's body and any clothing challenges they face. They spend more time shopping for the clothes that will make them look and feel their best.</ParagraphMedium>
            </ParagraphContainer>
            <ParagraphContainer>
                <ParagraphMedium bold={true}>You and your customers improve retail sustainability</ParagraphMedium>
                <ParagraphMedium>Happier customers can mean a lot of things to your brand: loyalty, advocacy, increased revenue, and decreased product returns. Using Catecut means shoppers are more likely to love what they buy and keep what they love. This significantly reduces both your and their environmental footprints, improving retail sustainability.</ParagraphMedium>
            </ParagraphContainer>
        </WidthStyledRow>
        <ImageContainer>
            <StyledImage src={IMGFilterByAttributes} borderColor="rgba(138, 189, 108, 1)" />
        </ImageContainer>
        </>
    )
}


const StyledRow = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 70px;
    @media ${Device.mobile} {
        flex-direction: column;
        gap: 0px;
    }
`

const WidthStyledRow = styled(StyledRow)`
    width: 1120px;
    align-items: start;
    margin-top: 50px;
    // margin-bottom: 90px;

    @media ${Device.mobile} {
        width: 95vw;
        margin-bottom: 50px;
    }
`

const MarginZHeadingH4 = styled(HeadingH4)`
    margin-top: 32px;
    margin-bottom: 15px;
`

const MarginZHeadingH5 = styled(HeadingH5)`
    margin-top: 25px;
    margin-bottom: 40px;
`

const ParagraphContainer = styled.div`
    flex-basis: 100%;

    @media ${Device.mobile} {
        margin-left: 15px;
        margin-right: 15px;
    }
`

const Spacer = styled.div`
    height: 20px;
`

// TODO - Not the best solution, but flex is another language altogether
// learn it
const ImageContainer = styled.div`
    box-sizing: content-box;
    width: 900px;

    @media ${Device.mobile} {
        width: 95vw;
        height: 250px;
    }
`

const VideoContainer = styled.div`
    border: solid 1px rgba(217, 185, 192, 1);
    border-radius: 8px;
    background-color: white;
    // This is necessary so the square video corners don't overlap the rounded borders
    // Applying the borders to the ReactPlayer itself is no help because the video is in an internal div that we can't style AFAIK
    padding-top: 8px;
    padding-bottom: 8px;
    
    @media ${Device.mobile} {
        width: 95vw;
    }
`