import styled from 'styled-components';
import { Device } from '../ui/Device/Device';

import SVGFacebook from './img/catecut-facebook-socials.svg';
import SVGInstagram from './img/catecut-instagram-socials.svg';
import SVGLinkedin from './img/catecut-linkedin-socials.svg';

export const SocialLinks = () => {
    return(
        <SocialContainer>
            <SocialLink href="https://www.facebook.com/findclothesforyou"><img alt="Facebook Link" src={SVGFacebook} /></SocialLink> 
            <SocialLink href="https://www.instagram.com/catecut.app/"><img alt="Instagram Link" src={SVGInstagram} /></SocialLink> 
            <SocialLink href="https://www.linkedin.com/company/rebutia"><img alt="LinkedIn Link" src={SVGLinkedin} /></SocialLink> 
        </SocialContainer>
    )
}

const SocialContainer = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center;
    width:380px;
    margin:auto;
    gap: 100px;
    @media ${Device.mobile} {
        width:300px;
        margin: 32px auto auto auto;
    }
`

const SocialLink = styled.a`
    margin:auto;
`