import styled from "styled-components";
import { ContactForm } from "./ContactForm";

import { SubtitleStyle, ParagraphSmall, ButtonInterStyle } from "../../Utility/TextStyles";
import { PrimaryButton } from "../Buttons/Buttons";
import { Device } from "../Device/Device";
import { Dropdown } from "../Buttons/Dropdown";

export const ContactCard = (props) => {

    return(
        <Container>
            <ContactRow>
                <Dropdown 
                    title='Contact us'
                    selectOnChangeHandler={props.selectOnChangeHandler}
                    types={props.types}
                    selected={props.selected}
                />
                <ContactSubtitle>{props.selected.title}</ContactSubtitle>
            </ContactRow>
            <ParagraphSmall>
                {props.children}
            </ParagraphSmall>
            <FormContainer>
                <ContactForm formId='contactForm' selected={props.selected} address='contact' />
            </FormContainer>
        </Container>
    )
}

const Container = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding: 0 32px 0 32px;
    width:100%;
    @media ${Device.mobile} {
        max-width:350px;
    };

`
const ContactRow = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    //padding: 0px 16px 0px 16px;
    width: auto;
    gap:80px;
    @media ${Device.mobile} {
        padding-top:16px;
        gap:16px;
        flex-direction:column;
        justify-content:center;
    };
`

const ContactSubtitle = styled(SubtitleStyle)``
    
const FormContainer = styled.div`
    width:100%;
    //max-height: ${props => props.expanded ? '600px' : '0px'};
    //overflow:hidden;
    //transition: max-height 200ms ease-in;
`