import styled from "styled-components";

import { HeadingH5, HeadingH2 } from "components/Utility/HeaderStyles";
import { ParagraphMedium, ParagraphSmall } from "components/Utility/TextStyles";
import IMGStylistPlugin from "../img/marketplace-maven.png";
import IMGRatingPlugin from "../img/rating-plugin.png";
import IMGFitFinder from "../img/fit-finder.png";
import IMGDeepFilter from "../img/deep-filter.png";
import IconClothChange from "../img/icon-cloth-change.png";
import IconCoatHanger from "../img/icon-coat-hanger.png";
import IconQRCode from "../img/icon-qr-code.png";
import IconDeepFiltering from "../img/icon-deep-filtering.png";
import { StyledImage } from "components/Utility/StackedImage";

import { Device, Size } from "../../Device/Device";

export const PluginsBlock = () => {
    return (
        <>
        <HeadingH2>AI-powered styling solution for</HeadingH2>
        <HeadingH2>for Online Apparel Retail</HeadingH2>
        <ParagraphSmall bold={true}>Ensuring every purchase is a better fit – for a more sustainable future of fashion</ParagraphSmall>
        <StyledRow>
            <StyledColumn>
                <TextContainer>
                    <HeadingH5>Marketplace Maven</HeadingH5>
                    <Icon src={IconClothChange}/>
                    <ParagraphMedium>Personalized fashion recommendations with AI-powered solution. The system automatically tags each item of clothing based on garment features and matches products with buyers, considering their unique body attributes and clothing challenges. Tailored specifically for online fashion platforms, bringing a new level of convenience and satisfaction to your buyers shopping experience.</ParagraphMedium>
                </TextContainer>
                <ImageContainer>
                    <StyledImage src={IMGStylistPlugin} borderColor="rgba(217, 185, 192, 1)"/>
                </ImageContainer>
            </StyledColumn>
            <StyledColumn>
                <TextContainer>
                    <HeadingH5>Resale TagMaster</HeadingH5>
                    <Icon src={IconCoatHanger}/>
                    <ParagraphMedium>Revolutionize your online marketplace environment with our cutting-edge solution. Effortlessly streamline your listing process with automated product tagging for uploaded items and a seamless background remover feature. Our AI-driven platform goes the extra mile by offering tailored recommendations for titles and descriptions, specifically crafted for the dynamic world of second-hand marketplaces. Say goodbye to tedious manual labor and hello to efficiency and optimization.</ParagraphMedium>
                </TextContainer>
                <ImageContainer>
                    <StyledImage src={IMGRatingPlugin} borderColor="rgba(0, 119, 130, 1)"/>
                </ImageContainer>
            </StyledColumn>
            <StyledColumn>
                <TextContainer>
                    <HeadingH5>FitFinder</HeadingH5>
                    <Icon src={IconQRCode}/>
                    <ParagraphMedium>In-store tablet/QR code solution delivering personalized fashion recommendations, reducing the reliance on retail assistance by catering to each customer's unique needs and preferences.</ParagraphMedium>
                </TextContainer>
                <ImageContainer>
                    <StyledImage src={IMGFitFinder} borderColor="rgba(232, 87, 127, 1)"/>
                </ImageContainer>
            </StyledColumn>
            <StyledColumn>
                <TextContainer>
                    <HeadingH5>Deep filtering</HeadingH5>
                    <Icon src={IconDeepFiltering}/>
                    <ParagraphMedium>Discover your perfect outfit effortlessly with our advanced deep filtering solution. Seamlessly navigate through clothing attributes to pinpoint exactly what you desire, whether it's a chic on-the-knee skirt, a flattering V-neckline top, or a sophisticated A-line dress. With our intuitive system, your ideal style is just a few clicks away, ensuring a seamless and enjoyable shopping experience every time.</ParagraphMedium>
                </TextContainer>
                <ImageContainer>
                    <StyledImage src={IMGDeepFilter} borderColor="rgba(200, 241, 245, 1)"/>
                </ImageContainer>
            </StyledColumn>
        </StyledRow>
        </>
    )
}


const StyledRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: ${Size.laptop * 0.8}px;
    margin-top: 10%;

    @media ${Device.mobile} {
        width: 90vw;
    }
`

const StyledColumn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 100px;
    
    :nth-child(even) {
        flex-direction: row-reverse;
    }

    @media ${Device.mobile} {
        flex-direction: column;
        gap: 30px;
    
        :nth-child(even) {
            flex-direction: column;
        }
    }
`

const TextContainer = styled.div`
    flex-basis: 100%;

    ${HeadingH5} {
        display: inline;
        text-align: start;
    }
`

const ImageContainer = styled.div`
    flex-basis: 100%;

    @media ${Device.mobile} {
        // width: 90vw;
    }
`

const Icon = styled.img`
    float: right;
    height: 45px;
`