import AnchorLink from "react-anchor-link-smooth-scroll"
import {React} from "react"
import styled from "styled-components"
import { navArray } from "../Marketplace/LandingContent"

const NavDotWrapper = (props) => {
    // AnchorLink is a bit of a holdover, does not work with current parallax implementation. Normal anchors is default.
    if(props.type==="smooth"){
        return(
            <AnchorLink href={props.navRef}>
                <NavDot isActive={("#"+props.activeNav) === props.navRef ? true : false}>
                    <HoverDot />
                </NavDot>
            </AnchorLink>
        )
    } else {
        return(
            <SimpleLink href={props.navRef}>
                <NavDot isActive={("#"+props.activeNav) === props.navRef ? true : false}>
                    <HoverDot />
                </NavDot>
            </SimpleLink>
        )
    }
}


const PageNav = (props) => {
    const getContainerOffset = (targetId) => {
        let elem = document.getElementById(targetId)

        if(elem){ 
            return((props.windowHeight - elem.offsetHeight)/2)
        }
        else { 
            return("0") 
        }
    }

    return(
        <NavDotBlock id="navContainer" offset={getContainerOffset("navContainer")}>
            {navArray.map((item, index) => 
                <NavDotWrapper type={props.type} navRef={"#"+item} key={index} activeNav={props.activeNav} />
            )}
        </NavDotBlock>
    )
}

export default PageNav;

/** NAV styles */

const SimpleLink = styled.a``

const NavDotBlock = styled.div`
    position:fixed;
    right:3vw;
    top:${props => props.offset+"px"};
    z-index:2;
`

const NavDot = styled.div`
    position:relative;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    margin-bottom:8px;
    width: 16px;
    height: 16px;

    /* UI/White */
    background: ${props => props.isActive ? "black" : "white"};

    /* UI/Black */
    border: 1.5px solid #000000;
    border-radius: 16px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`
const HoverDot = styled.div`
    position: absolute;
    width: 8px;
    height: 8px;
    left: calc(50% - 8px/2);
    top: calc(50% - 8px/2);
    border-radius:8px;

    /* UI/Black */
    background: ${props => props.dotColor ? props.dotColor : "#000000"};

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;

    display:none;
    ${NavDot}:hover & {
        display:inline;
    }
`
