import { useState } from 'react'
import styled from 'styled-components'
import { Device } from '../Device/Device'
import LogoDotSVG from './img/Catecut_logo_dot.svg'
import LogoTextSVG from './img/Catecut_logo_text.svg'
//import LogoPNG from './img/Catecut_logo_WEBSITE_ONLY.png'


export const LOGOCatecutSVG = (props) => { 
    const [isAnimating, setAnimating] = useState(false);

    return(
    <StyledLogo>
        {props.size === "mobile" ? 
            <StyledLogoText 
                src={LogoTextSVG} 
                alt="The Catecut logo" 
                onClick={()=>setAnimating(true)}
                onAnimationEnd={()=>setAnimating(false)}
                animating={isAnimating}
            />
        : 
            <StyledLogoText 
                src={LogoTextSVG} 
                alt="The Catecut logo" 
                onMouseOver={()=>setAnimating(true)}
                onAnimationEnd={()=>setAnimating(false)}
                animating={isAnimating}
        />}
        <StyledLogoDot 
        src={LogoDotSVG}
        alt="The Catecut Logo"
        />
    </StyledLogo>
)}

const StyledLogo = styled.div`
    position:relative;
    top:-40px;
    left:-40px;
    @media ${Device.mobile} {
        top:-35px;
        left:-35px;
    }
`

const StyledImage = styled.img`
    background: transparent;
    position: absolute;
    object-fit: cover;
`

const StyledLogoText = styled(StyledImage)`
    //background:#D1D1D1;
    width: 150px;
    height: 149px;
    z-index: 5;
    ${props => props.animating ? "animation:spin 1s ease-in-out 1; " : "" }
    @media ${Device.mobile} {
        height: 125px;
        width: 125px;
    }
    @keyframes spin {
        100% {
            transform:rotate(360deg);
        }
    }
`

const StyledLogoDot = styled(StyledImage)`
    //background:#E1E1E1;
    width: 150px;
    height: 149px;
    z-index: 4;
    @media ${Device.mobile} {
        height: 125px;
        width: 125px;
    }
`