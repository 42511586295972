import React from 'react'
import styled from 'styled-components';
import { Link } from "react-router-dom";
import MailchimpFormContainer from '../ui/MailchimpFormContainer/MailchimpFormContainer';
import { HeadingH5 } from '../Utility/HeaderStyles';
import { ParagraphSmall } from '../Utility/TextStyles';
import { Device } from '../ui/Device/Device';
import { SocialLinks } from './Socials';


import PNGRannisLogo from "./img/rannis-logo.png";
import IMGAMKLogo from "./img/atvinnumal-kvenna-logo.png";
import IMGIIIMLogo from "./img/iiim-logo.png";
import IMGTDFLogo from "./img/technology-development-fund.png";

const Footer = () => {
        return(
            <Container>
                <Divider margin="0 0 60px 0" />

                <HeadingH5>Our partners</HeadingH5>
                <CollabContainer>
                    {/* <CollabLogo src={PNGRannisLogo} /> */}
                    <CollabLogo src={IMGAMKLogo} />
                    <CollabLogo src={IMGIIIMLogo} />
                    <CollabLogo src={IMGTDFLogo} />
                </CollabContainer>

                <Divider/>

                <StyledRow>
                    <StyledColumn>
                        <FooterLink to="/support">Support</FooterLink>
                        <FooterLink to="/contact">Contact us</FooterLink>
                    </StyledColumn>
                    <StyledColumn>
                        <SocialLinks />
                        <StyledMailchimp>
                            <MailchimpFormContainer type="newsletter" />
                        </StyledMailchimp>
                    </StyledColumn>
                </StyledRow>

                <Divider margin='85px 0 60px 0'/>

                <LegalBlock>
                    <LegalText>Copyright © 2024 All rights reserved</LegalText>
                    <LegalLink to="/terms">Terms and Conditions</LegalLink>
                    <LegalLink to="/privacy">Privacy Policy</LegalLink>
                </LegalBlock>
                <ContactBlock>
                    <ContactText>Sowilo ehf | Eiðistorgi 13-15 | 170 Seltjarnarnesi | Iceland | Europe</ContactText>
                    <ContactText>Rebutia Inc | 600 N Broad Street  |  Suite 5  | Middletown, DE 19709  | USA</ContactText>
                </ContactBlock>
            </Container>
        )
    }
    
    export default Footer;

const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    align-items:center;
    padding-bottom: 16px;
    margin-bottom: ${props => props.type === "legal" ? "85px" : "auto"};
    background:transparent;
`

const Divider = styled.div`
    width: 90%;
    border: 1px solid #000000;
    margin: ${props => props.margin ? props.margin : "0 0 85px 0" };
`

const Logo = styled.div`
    font-family: 'Inter';
    font-style: normal;
    
    font-weight: 400;
    font-size: 24px;
    line-height: 170%;
    color: #000000;
`

const StyledAppDownload = styled.a`
    margin:0;
    padding:0;
`

const FooterH5 = styled(HeadingH5)`
    width:660px;
    @media ${Device.tablet} {
        margin:auto;
        width:100%;
    };
`

const FooterText = styled(ParagraphSmall)`
    margin:0;
    padding:0;
`

const FooterLink = styled(Link)`
    font-family: 'Inter';
    font-style: normal;
    font-weight: "400";
    font-size: 16px;
    line-height: 160%;
    color: #000000;
    text-decoration-line: none;

    margin:0;
    padding:0;
`

const FooterSimpleLink = styled.a`
    font-family: 'Inter';
    font-style: normal;
    font-weight: "400";
    font-size: 16px;
    line-height: 160%;
    color: #000000;
    text-decoration-line: none;

    margin:0;
    padding:0;
`

const FooterHeader = styled(ParagraphSmall)`
    color:#4e4e4e;
    margin:0;
    padding:0;
`

const StyledRow = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content: center;
    align-items: start, center;
    gap:128px;

    @media ${Device.tablet} {
        gap:56px;
        margin-top:64px;        
        flex-direction:column;
      };
`
const StyledColumn = styled.div`
    position:relative;
    display: ${props => props.hidden ? "hidden" : "flex"};
    flex-direction: column;
    align-items: flex-start, center;
    text-align:center;
    gap:16px;
    max-width:100%;
`

const LegalBlock = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    @media ${Device.tablet} {
        flex-direction:column;
      };
`

const LegalText = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin: auto 8px auto 8px;
`

const LegalLink = styled(Link)`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin: auto 8px auto 8px;
    text-decoration-line: underline;
    color:black;
`

const LegalSimpleLink = styled.a`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin: auto 8px auto 8px;
    text-decoration-line: underline;
    color:black;
`

const ContactBlock = styled(LegalBlock)`
    margin-top:16px;
    flex-direction:column;
`

const ContactText = styled(LegalText)`
    font-size: 12px;
    text-align:center;
    @media ${Device.tablet} {
      };
`


const StyledMailchimp = styled.div`
    margin: auto;
`

const CollabContainer = styled.div`
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;

    justify-content:center;
    align-items:center;

    width:100%;

    padding:0px;
    gap:32px;
    margin-top: 40px;
    margin-bottom: 40px;
`

const CollabLogo = styled.img`
    object-fit:contain;
    height:200px;
`