import { useEffect, useState } from "react";
import styled from "styled-components"
import { HeadingH1, HeadingH2, HeadingH5, HeadingH6, PzLarge, PzMedium } from "../Utility/HeaderStyles"
import { EyebrowStyle, ParagraphMedium, ParagraphSmall, SubtitleStyle } from "../Utility/TextStyles"
import { Collapsible } from "../ui/Buttons/Collapsible";
import Footer from "../Footers/Footer";
import { StyledGridCol } from "../Utility/LayoutStyled";
import Navbar from "../ui/Nav/Navbar";
import { ContactForm } from "../ui/Contact/ContactForm";
import { invokeLambdaFunction } from "../Utility/invokeLambdaFunction";
import { Device } from '../ui/Device/Device';
import ReactPlayer from 'react-player'
import { StyledGridRow } from "../Utility/LayoutStyled";

export const ContactSalesContainer = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const ContactAddress = process.env.REACT_APP_FORM_CONTACT_ADDRESS

    useEffect(()=>{
        window.addEventListener('resize', (e) => {
            setWindowHeight(window.innerHeight);
            setWindowWidth(window.innerWidth);
        })
    })
    return(
        <Container id="b2bContainer" windowWidth={windowWidth}>
            <Navbar windowWidth={windowWidth}/>
            <Content>
                    {/* <HeadingH2>Let's have a</HeadingH2>
                    <PzMedium>chat</PzMedium> */}
                    {/* <HeadingH6>For business inquiries, please use the form below: </HeadingH6> */}
                    <ContactForm formId='businessForm' selected= {{type:"business", title:"Business contact request"}} address={ContactAddress} />
                <SupportSpacer />
                <Footer type="short"/>
            </Content>
        </Container>
    )
}
/*
            */

const ListHeading = styled(HeadingH6)`
    margin-top:24px;
    text-align: left;
`

const ListText = styled(ParagraphMedium)`
    margin:auto;
`

const List = styled.ul`
    margin-top:24px;
    text-align: left;
    padding-left: 1.5em;
    @media ${Device.mobile} {
        padding-left: 2.5em;
    }
`
const SubList = styled.ul`
    text-align: left;
    padding-left: 2.5em;
`

const ListItem = styled.li`
`

const SupportLink = styled.a`
    color:black;
    font-style:italic;
`

const SupportSpacer = styled.div`
    margin-bottom:80px;
`

const StyledRow = styled.div`
    position:relative;
    display:flex;
    flex-direction:row;
    align-items:top;
    @media ${Device.mobile} {
        flex-direction:column-reverse;
    }
`

const VideoContainer = styled.div`
    margin-right:16px;
    width:285px;
    height:577px;
    background:transparent;
    @media ${Device.mobile} {
        margin:auto;
    }
`

const ListContainer = styled.div`
    margin-left:24px;
    @media ${Device.mobile} {
        margin-left:20px;
        margin-right:20px;
    }
`

const Content = styled.div`
    position:absolute;
    width: 100%;
    height:auto;
    top: 120px;
    left: 0;
    right: 0;
    margin: auto;
    min-width: 375px;
    max-width: 900px;
    align-items: top;
    overflow: hidden;
    background:transparent;
`

const Container = styled.div`
    background-color:transparent;
`

const StyledSubtitle = styled(SubtitleStyle)`
padding-top:80px;

@media ${Device.mobile} {
    font-size:24px;
    padding-top:80px;
    margin-left:20px;
    margin-right:20px;
}
`

const Divider = styled.div`
margin-top:24px;
width:100%;
height:1px;
background: #DDD;
`

const StyledH1 = styled(HeadingH1)`
    @media ${Device.mobile} {
        font-size:30px;
    }
`

const StyledPzL = styled(PzLarge)`
    @media ${Device.mobile} {
        font-size:40px;
    }
`