import MailchimpSubscribe from 'react-mailchimp-subscribe';
import MailchimpForm from './MailchimpForm';


const MailchimpFormContainer = props => {
    const newsletterURL = `https://rebutia.us20.list-manage.com/subscribe/post?u=${process.env.REACT_APP_NEWSLETTER_USER}&id=${process.env.REACT_APP_NEWSLETTER_ID}`

    return (
        <>
            <MailchimpSubscribe 
                url={newsletterURL}
                render={({subscribe, status, message}) => (
                    <MailchimpForm 
                        audience={props.audience}
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </>
    )
}

export default MailchimpFormContainer;

