import React, {useState, useEffect} from "react";
import styled from "styled-components";

import { theme } from "../../theme";

import { useParams, useNavigate } from "react-router-dom";

import DotContainer from '../ui/DotContainer/DotContainer';
import { BlogNav } from "../ui/Nav/BlogNav";

import { Article } from "../ui/Blog/Article";
import { Preview } from "../ui/Blog/Preview";

import { HPzL, TextBody } from "../ui/Landing/LandingStyles";

import { useQuery, gql } from '@apollo/client';
import { GET_BLOGPOSTS, GET_BLOGPOST } from "../ui/Blog/Queries";

export const BlogContainer = () => {
    const { page } = useParams();
    const { article } = useParams();
    const navigate = useNavigate();

    const pageLimit = 4;
    const pageSkip = page !== undefined ? page * pageLimit : 0

    const [blogContent, setContent] = useState();
    const [blogPage, setPage] = useState(page !== undefined ? Number(page) : 0)
    const [blogPageTotal, setPageTotal] = useState(1);

    const [Query, QueryVars] = 
        article === undefined 
        ? [GET_BLOGPOSTS, { "limit" : pageLimit, "skip" : pageSkip }] 
        : [GET_BLOGPOST, { "id" : article }]


    const {loading, error, data} = useQuery(
        Query, { variables: QueryVars }
    );
    
    const handleOnClickNext = () => {
        if(page === undefined){
            navigate(`/blog/${1}`)
        }

        if(page < blogPageTotal){
            navigate(`${Number(page)+1}`)
        }
    }
    const handleOnClickPrevious = () => {
        if(page > 0){
            navigate(`${Number(page)-1}`)
        }
    }

    const handleOnClickPreview = (id) => {
        navigate(`/blog/article/${id}`)
    }

    useEffect(() => {
        if(!loading){
            if(data.blogPostCollection !== undefined){
                setPageTotal(Math.ceil((data.blogPostCollection.total / pageLimit)) - 1);
                setContent(data.blogPostCollection);
            } else if(data.blogPost){
                setContent(data.blogPost);
            }

            if(page !== undefined){
                setPage(page)
            }
        }
    },[data,loading,page])
    
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;

    if (article !== undefined){
        return(
            <Container>
                <ContentContainer>
                    {blogContent ?
                        <Article
                        key={0}
                        articleId={article}
                        item={blogContent}
                        /> : <></>
                    }
                </ContentContainer>
                <DotContainer />
            </Container>)
    } else {
        return (
            <Container>
                <ContentContainer>
                    <PreviewContainer>
                        {blogContent?.items?.map((item, index) => 
                            <Preview 
                            key={item.sys.id} 
                            item={item} 
                            handleClick={handleOnClickPreview} 
                            />
                            )}
                    </PreviewContainer>
                    <BlogNav handlePrevious={handleOnClickPrevious} handleNext={handleOnClickNext} />
                </ContentContainer>
                <DotContainer />
            </Container>
        )
    }
}

const PreviewContainer = styled.div`
    position:relative;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    background:transparent;
`

const ContentContainer = styled.div`
    position:absolute;
    width: 100%;
    height:auto;
    top:0px;
    left:0px;
    margin: auto;
    align-items: top;
    overflow: hidden;
    background:transparent;
`

const Container = styled.div`
    position: absolute;
    margin:auto;
    height:100%;
    width:100%;
    left:0;
    scroll-behavior:smooth;
    
    perspective: ${theme.perspective+"px"};
    perspective-origin: 0 0;

    background-color:transparent;
    overflow-x:hidden;
    display:flex;
    -webkit-overflow-scrolling: touch; // iOS scroll fix
`