export const DoYouWantToShineLines = [
    "Yas! Show me, PLEASE!",
    "Yas! Turn on the switch!",
    "Yas! Make me a QUEEN!",
    "Yas! Go for the GLOW!",
    "Yas! Illuminate me.",
    "Yas! Give me my SPARKLE!",
    "Yas! I'm a glitter BOMB!",
    "Yas! I'm a freakin' ray of light.",
    "Yas! Light me UP!",
    "Yas! Enlighten me!",
    "Yas! Diva, diva, diva!",
    "Yas! Otherwise known as 'yes.'",
    "Yas! I dooooo.",
    "Yas! Who wouldn't?",
    "YAS. OMG YAS."
]

export const FancyPantsLines = [
    "Use sophisti-Cated AI to find fancy pants!"
]

export const RightOutfitLines = [
    "From drab to fab. Snag your swag. We got you!",
    <>Look the part. <br /> Get the part.</>,
    "This is Cate. She'll take you where you need to go."
]

export const BugginLines = [
    "Serving you style on a silver platter. Take what you want!"
]