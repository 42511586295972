import React, { useState } from "react"
import styled from "styled-components"
import { HeadingH2 } from "../../../Utility/HeaderStyles"
import { EyebrowStyle } from "../../../Utility/TextStyles"
import { PrimaryButton } from "../../Buttons/Buttons"
import { Device } from "../../Device/Device"

import ArrowRW from './../../Buttons/img/right-arrow-white.svg'
import ArrowRB from './../../Buttons/img/right-arrow-black.svg'

import QuickPeek1IMG from "./img/quickpeek-1.png"
import QuickPeek2IMG from "./img/quickpeek-2.png"
import QuickPeek3IMG from "./img/quickpeek-3.png"

export const QuickPeekContainer = () => {

    const [currentPeek, setCurrent] = useState('qpMeasure');

    const handleSelectPeek = (e) => {
        setCurrent(e.target.id)
    }

    return(
        <Container>
            <Col>
                <TextContainer>
                    <QPEyebrow>See it for yourself</QPEyebrow>
                    <QPHeadingH2>A quick peek at the basics</QPHeadingH2>

                    <ButtonContainer>
                        <QuickPeekButton id='qpMeasure' onClick={(e) => handleSelectPeek(e)}>Measurements</QuickPeekButton>
                        <QuickPeekButton id='qpRecommend' onClick={(e) => handleSelectPeek(e)}>Clothing recommendations</QuickPeekButton>
                        <QuickPeekButton id='qpPalette' onClick={(e) => handleSelectPeek(e)}>My color palette</QuickPeekButton>
                    </ButtonContainer>
                </TextContainer>
            </Col>
            <ColImage>
                <ImageContainer>
                    <QuickPeekImage imageSrc={currentPeek} />
                </ImageContainer>
            </ColImage>
        </Container>)
}

const QuickPeekImage = (props) => {
    switch(props.imageSrc){
        case 'qpRecommend':
            return(<StyledQuickPeekImage src={QuickPeek2IMG} />)
        case 'qpMeasure':
            return(<StyledQuickPeekImage src={QuickPeek1IMG} />)
        case 'qpPalette':
            return(<StyledQuickPeekImage src={QuickPeek3IMG} />)
        default:
            return(<StyledQuickPeekImage src={QuickPeek1IMG} />)

    }
}

const Col = styled.div`
    display:flex;
    @media ${Device.mobile} {
        width:100%;
    }
`

const ColImage = styled.div`
    display:flex;
    width:50%;
    @media ${Device.mobile} {
        width:100%;
}
`

const QPEyebrow = styled(EyebrowStyle)`
    text-align:left;
    margin:0;
`

const QPHeadingH2 = styled(HeadingH2)`
    text-align:left;
    margin:0;
`

const StyledQuickPeekImage = styled.img`
    object-fit:contain;
    height:580px;
    margin:0;
    padding:0;
    filter:drop-shadow(4px 4px 8px rgba(0,0,0,0.15));
`

const Container = styled.div`
    display:flex;
    display-direction: row;
    justify-content:space-between;
    flex-wrap: wrap;
    align-items:center;
    gap:40px;
    width:80vw;
`

const ImageContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin:0px;

    @media ${Device.mobile} {
    }
`

const TextContainer = styled.div`
display:flex;
flex-direction:column;
align-items:flex-start;
text-align:left;
width:316px;
gap:16px;
margin:0px;
padding:0px;
`

const ButtonContainer = styled.div`
display:flex;
flex-direction:column;
gap:16px;
`


const QuickPeekButton = styled(PrimaryButton)`
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;

letter-spacing: 0.2px;

background-color:white;
border: 1.5px solid black;
color:black;

height:54px;
width:316px;

&:after{
    content:url(${ArrowRB});
}

&:hover:after{
    content:url(${ArrowRW});
}

&:hover{
    background-color:black;
    color:white;
}
`