import { HPzS, TextBody, TextSmall } from "../../ui/Landing/LandingStyles";
import styled from "styled-components";

export const Article = (props) => {
    const blogLinks = []

    props.item?.article?.links.assets.block.forEach((item)=>{
        blogLinks.push({
            "id" : item.sys.id,
            "url" : item.url,
            "description" : item.description
        })
    })

    return (
        props.item ? 
        <>
            <h1>{props.item?.title}</h1>
            <img height="400" src={props.item.articleImage?.url}/>
            {props.item.article?.json.content.map((item, index) =>
                <Block key={index} item={item} links={blogLinks} />
            )}
        </> : <></>
    )
}


const Block = (props) => {
    if(props.item.nodeType === "paragraph"){
        return(
            props.item.content.map((paragraph,index)=>
                <Paragraph item={paragraph} key={index} />
            )   
        )
    }

    if(props.item.nodeType === "embedded-asset-block"){
        return(
            <EmbedImage item={props.item} links={props.links}  />
        )
    }

    return(<></>)
}

const Paragraph = (props) => {
    return(
            <TextSmall>
                {props.item.value}
            </TextSmall>
)
}

const EmbedImage = (props) => {
    const assetLink = props.links?.filter(obj => {
        return obj.id === props.item.data.target.sys.id
    })[0]
    
    return(
        <>
            <img src={assetLink?.url} alt="things" height="200" />
            <TextSmall>{assetLink?.description}</TextSmall>
        </>
    )
}

const StyledArticle = styled.div`
height:auto;
`