import React, {useEffect} from "react"
import styled from "styled-components"

import Footer from '../../Footers/Footer';

import { Device } from '../Device/Device';

import { PluginsBlock } from "./Blocks/PluginsBlock";
import { ClothingBlock } from "../Landing/Blocks/ClothingBlock";
import { PricingBlock } from "./Blocks/PricingBlock";


export const navBlocks = {
    PLUGINS: "Solutions",
    CLOTHING: "TheRightClothing",
    // PRICING: "Pricing",
    FOOTER: "TheEnd"
};

export const SolutionsContent = (props) => {
    return(
        <Landing id="landingContainer">
            <ContentBlock id="topContainer" marginTop="150" marginBottom="100">
                <NavAnchor id={navBlocks["PLUGINS"]} offset={1000}/>
                <PluginsBlock/>
            </ContentBlock>

            <ContentBlock>
                <NavAnchor id={navBlocks["CLOTHING"]} offset={props.windowHeight / 2} />
                <ClothingBlock />
            </ContentBlock>
            
            {/* <ContentBlock>
                <NavAnchor id={navBlocks["PRICING"]} offset={700}/>
                <PricingBlock />
            </ContentBlock> */}
            
            <ContentBlock id="bottomContainer" marginBottom={props.variableMargins["bottom"]}>
                <NavAnchor id={navBlocks["FOOTER"]} offset={props.windowHeight / 2}/>
                <Footer type="short"/>
            </ContentBlock>
        </Landing>
    )
}

const Landing = styled.div`
    position:absolute;
    width: 100%;
    height:auto;
    top:0px;
    left:0px;
    margin: auto;
    align-items: top;
    overflow: hidden;
    background:transparent;
`

const ContentBlock = styled.div`
    padding-left: ${props => props.noPadding === true ? "auto" : "5vw" };
    padding-right: ${props => props.noPadding === true ? "auto" : "5vw" };
    margin-top: ${props => props.marginTop ? props.marginTop+"px" : "auto"};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom+"px" : "300px"};
    display: ${props => props.display ? props.display : "flex"};
    flex-direction: column;
    align-items: center;
    position: relative;

    @media ${Device.mobile} {
        min-width:350px;
        width: 90vw;
        margin-left:auto;
        margin-right:auto;
        margin-top: ${props => props.marginTop ? (props.marginTop * 0.75)+"px" : "auto" };
        margin-bottom: ${props => props.marginBottom ? props.marginBottom+"px" : "225px"};
    }
`

const NavAnchor = styled.div`
    position: absolute; 
    bottom: calc(50% - 10px + ${props => props.offset+"px"});
`
