import styled from "styled-components";
import { Device } from "components/ui/Device/Device";

import { HeadingH1, HeadingH5 } from "components/Utility/HeaderStyles"
import { PrimaryButton } from "../../Buttons/Buttons";
import IMGCheckMark from "../img/check-mark.svg";
import IMGToolTipSymbol from "../img/tool-tip-symbol.svg";
import { ParagraphLarge, ParagraphMedium, ParagraphSmall } from "components/Utility/TextStyles";

export const PricingBlock = () => {
    return (
        <>
        <HeadingH1>Pricing</HeadingH1>
        <StyledTable>
            <StyledColGroup>
                <StyledCol backgroundColor="transparent"/>
                <StyledCol backgroundColor="rgba(221, 245, 255, 1)"/>
                <StyledCol backgroundColor="rgba(199, 255, 233, 1)"/>
                <StyledCol backgroundColor="rgba(232, 255, 183, 1)"/>
            </StyledColGroup>
            <StyledTableHead>
                <StyledTableRow>
                    <StyledTableHeader scope="col">
                        <HeadingH5>Features</HeadingH5>
                    </StyledTableHeader>
                    <StyledTableHeader scope="col">
                        <ParagraphSmall>PRO</ParagraphSmall>
                        <ParagraphSmall bold={true}>SKU size up to 1K</ParagraphSmall>
                        <ParagraphSmall>From <InlinePrice>€300</InlinePrice> / month</ParagraphSmall>
                        <BookADemoButton light={true} width="216px" height="54px" href="/sales">Book a demo</BookADemoButton>
                    </StyledTableHeader>
                    <StyledTableHeader scope="col">
                        <ParagraphSmall>ELITE</ParagraphSmall>
                        <ParagraphSmall bold={true}>SKU size up to 10K</ParagraphSmall>
                        <ParagraphSmall>From <InlinePrice>€1000</InlinePrice> / month</ParagraphSmall>
                        <BookADemoButton light={true} width="216px" height="54px" href="/sales">Book a demo</BookADemoButton>
                    </StyledTableHeader>
                    <StyledTableHeader scope="col">
                        <ParagraphSmall>ENTERPRISE</ParagraphSmall>
                        <ParagraphSmall bold={true}>SKU size above 10K</ParagraphSmall>
                        <ParagraphSmall>Flexible pricing</ParagraphSmall>
                        <BookADemoButton light={true} width="216px" height="54px" href="/sales">Book a demo</BookADemoButton>
                    </StyledTableHeader>
                </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
                <StyledTableRow>
                    <StyledTableHeader scope="row">
                        <InlineParagraph bold={true}>AI product tagging</InlineParagraph>
                        <ToolTip>AI retrieves products from the API and identifies clothing features from images</ToolTip>
                    </StyledTableHeader>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader scope="row">
                        <InlineParagraph bold={true}>Personalized product recommendation</InlineParagraph>
                        <ToolTip>Buyers receive a personal product recommendation based on their body attributes and clothing challenges</ToolTip>
                    </StyledTableHeader>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader scope="row">
                        <InlineParagraph bold={true}>Auto generated why fit description</InlineParagraph>
                        <ToolTip>Provides an insightful explanation of why the recommended item is an ideal fit for the user</ToolTip>
                    </StyledTableHeader>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader scope="row">
                        <InlineParagraph bold={true}>Performance metrics</InlineParagraph>
                        <ToolTip>Data showcasing changes in returns and conversions</ToolTip>
                    </StyledTableHeader>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader scope="row">
                        <InlineParagraph bold={true}>Refinement options</InlineParagraph>
                        <ToolTip>Offer personalized filtering, including the ability to exclude sizes that don't match the buyer's preferences</ToolTip>
                    </StyledTableHeader>
                    <StyledTableData><ParagraphSmall>Add-on</ParagraphSmall></StyledTableData>
                    <StyledTableData><ParagraphSmall>Add-on</ParagraphSmall></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader scope="row">
                        <InlineParagraph bold={true}>Automated product descriptions</InlineParagraph>
                        <ToolTip>Our AI generates compelling descriptions for new items</ToolTip>
                    </StyledTableHeader>
                    <StyledTableData><ParagraphSmall>Add-on</ParagraphSmall></StyledTableData>
                    <StyledTableData><ParagraphSmall>Add-on</ParagraphSmall></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader scope="row">
                        <InlineParagraph bold={true}>Outfit recommendations</InlineParagraph>
                        <ToolTip>Providing outfit suggestions tailored to the buyer's recommended clothing</ToolTip>
                    </StyledTableHeader>
                    <StyledTableData><ParagraphSmall>Add-on</ParagraphSmall></StyledTableData>
                    <StyledTableData><ParagraphSmall>Add-on</ParagraphSmall></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader scope="row">
                        <InlineParagraph bold={true}>Advanced statistical analysis</InlineParagraph>
                        <ToolTip>Analyzing data to identify optimal fits and styles for buyer profiles, pinpointing gaps in offerings, and calculating CO2 savings</ToolTip>
                    </StyledTableHeader>
                    <StyledTableData><ParagraphSmall>Add-on</ParagraphSmall></StyledTableData>
                    <StyledTableData><ParagraphSmall>Add-on</ParagraphSmall></StyledTableData>
                    <StyledTableData><CheckMarkImage/></StyledTableData>
                </StyledTableRow>
            </StyledTableBody>
        </StyledTable>
        <NoWidthLargeParagraphe>
            Fill out a 3 minute form and we will send you an offer.<br/>
            Or click here to book us for a 15 minute demo.<br/>
            We look forward to meeting you!
        </NoWidthLargeParagraphe>
        <ButtonContainer>
            <PrimaryButton width="264px" target="_blank" href="https://forms.gle/j44MfBD4RmtvK1dZ7">Get an offer</PrimaryButton>
            <PrimaryButton width="264px" light={true} href="/sales">Book a demo</PrimaryButton>
        </ButtonContainer>
        </>
    )
}


const StyledTable = styled.table`
    margin-top: 80px;
    border: 1px solid rgba(149, 163, 214, 1);
    // not possible to use border-collapse: collapse together with border-radius, so we must cheat 
    border-collapse: separate;
    border-spacing: 0px;
    border-radius: 8px;
    text-align: center;
    width: 80vw;

    ${HeadingH5} {
        text-align: left;
    }

    @media ${Device.mobile} {
        display: block;
        width: 95vw;
        // max-height: calc(100vh - 80px);
        overflow-x: scroll;
        // overflow-y: clip;
    }
`

const StyledTableBody = styled.tbody`
    tr {
        height: 75px;
    }
`

const StyledTableRow = styled.tr`
`

const StyledColGroup = styled.colgroup`
`

const StyledCol = styled.col`
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    min-width: 25%;

    @media ${Device.mobile} {
        min-width: 45vw;
    }
`

const StyledTableHeader = styled.th`
    border-left: 1px solid rgba(149, 163, 214, 1);
    border-top: 1px solid rgba(149, 163, 214, 1);
    :first-child {
        border-left: none;
        text-align: left;
        padding: 0px 24px;

        @media ${Device.mobile} {
            padding: 0px 10px;
        }
    }
`

const StyledTableData = styled.td`
    border-left: 1px solid rgba(149, 163, 214, 1);
    border-top: 1px solid rgba(149, 163, 214, 1);
`

const CheckMarkImage = styled.img.attrs({
    src: `${IMGCheckMark}`
})``

const ToolTip = ({children}) => {
    return (
        <>
        <ToolTipSymbolImage/>
        <ToolTiptext>{children}</ToolTiptext>
        </>
    )
}

const ToolTiptext = styled.div`
    position: absolute;
    visibility: hidden;
    max-width: 248px;
    background-color: rgba(237, 244, 255, 1);
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1));
    border-radius: 4px;
    padding: 12px 20px 12px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
`

const ToolTipSymbolImage = styled.img.attrs({
    src: `${IMGToolTipSymbol}`
})`
    float: right;
    cursor: help;
    :hover ~ ${ToolTiptext} {
        visibility: visible;
    }

    @media ${Device.mobile} {
        display: none;
    }
`

const InlineParagraph = styled(ParagraphMedium)`
    display: inline;

    @media ${Device.mobile} {
        font-size: 14px;
    }
`

const StyledTableHead = styled.thead`
    & th {
        border-top: none;
    }
`

const InlinePrice = styled.span`
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;

    @media ${Device.mobile} {
        font-size: 22px;
    }
`

const BookADemoButton = styled(PrimaryButton)`
    font-family: 'Inter';
    font-size: 16px;
    margin-bottom: 12px;

    @media ${Device.mobile} {
        display: none;
    }
`

const NoWidthLargeParagraphe = styled(ParagraphLarge)`
    width: auto;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;

    @media ${Device.mobile} {
        flex-direction: column;
    }
`