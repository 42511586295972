import { useMemo } from "react"

/* useMemo is used to cache the selected line, otherwise it will change with every re-render (such as when scrolling) */
const GetRandomLine = (lineList) => {
    const cachedLine = useMemo(() => lineList[Math.floor(Math.random() * lineList.length)],[lineList])
    return cachedLine
}

export const RandomLine = (props) => {
    return(
        <>
            {GetRandomLine(props.lineList)}
        </>
    )
}