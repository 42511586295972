import styled from "styled-components";
import { Device } from "../Device/Device";

/*
Secondary: bool
State: bool
Icon: bool
IconPos: left/right
Text: bool
VoiceOn: bool
*/

export const PrimaryButton = styled.a`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: ${props => props.width ? props.width : '303px'};
    height: ${props => props.height ? props.height : '60px'};

    padding: ${props => props.padding ? props.padding : '16px 32px'};
    gap: 16px;
    margin: ${props => props.margin ? props.margin : 'auto'};
    
    background-color: ${ props => props.state === 'disabled' ? '#E8E8E8' : (props.light ? "white" : "black") };
    border-radius: 30px;
    border: 1.5px solid black;

    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: ${ props => props.state === 'disabled' ? '#4E4E4E' : (props.light ? "black" : "white") };
    

    text-align: center;
    letter-spacing: 0.3px;
    text-decoration: none;

    @media ${Device.mobile} {
        margin: ${props => props.mMargin ? props.mMargin : ''};
        width: ${props => props.mWidth ? props.mWidth : ''};
        font-size: ${props => props.mFontSize ? props.mFontSize : ''}
    }

    &:before{
        content: url(${props => props.iconPosition === 'left' ? props.icon : ''});
    }
    &:after{
        content: url(${props => props.iconPosition === 'right' ? props.icon : ''});
    }
    &:hover{
        color: ${props => props.light ? "white" : "black" };
        background-color: ${props => props.light ? "black" : "white" };
        border: 1.5px solid black;
        transition-duration: 500ms;
    }
`

export const CircularButton = styled(PrimaryButton)`
    width:60px;
    height:60px;
    padding:0px;

    color: ${ props => props.state === 'disabled' ? '#4E4E4E' : 'black' }
    background: ${ props => props.state === 'disabled' ? '#E8E8E8' : 'white' }
    border: ${ props => props.state === 'disabled' ? '1.5px solid #4E4E4E;' : '1.5px solid black;' }
    border-radius:50%;

    &:before{
        content: url(${props => props.icon});
    }

    &:hover{
        color:white;
        background-color:black;
    }
`
