import { gql } from "@apollo/client";

export const GET_BLOGPOSTS = gql`
  query GetBlogPosts ($limit: Int!, $skip: Int!) {
    blogPostCollection (limit: $limit, skip: $skip, order: sys_firstPublishedAt_DESC){
      total,
      items {
        sys{
            id,
            publishedAt,
            firstPublishedAt
          }
        contentfulMetadata{
          tags{
            id,
            name
          }
        },
        title,
        article {
          json,
          links{
            assets {
              block {
                sys {
                  id
                }
                description,
                url
              }
            }
          }
        },
        articleImage{
          sys{id}
          url,
          description
        }
      }
    }
  }
`

export const GET_BLOGPOST = gql`
query GetBlogPost ($id: String!) {
  blogPost (id:$id){
    sys {
        id,
        publishedAt,
        firstPublishedAt
    }
      contentfulMetadata{
        tags {
          id,
          name
        }
      },
      title
      article {
        json,
        links {
          assets {
            block {
                sys {id}
                description
                url
            }
          }
        }
      },
      articleImage{
        sys {id}
        url,
        description
      }
    }
  }
`