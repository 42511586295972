import React, {useEffect} from "react"
import styled from "styled-components"

import Footer from '../../Footers/Footer';

import { ParagraphLarge, ParagraphMedium, EyebrowStyle} from "../../Utility/TextStyles";


import { Device } from '../Device/Device';

import { PluginsBlock } from "./Blocks/PluginsBlock";
import { SustainabilityBlock } from "./Blocks/SustainabilityBlock";
import { ClothingBlock } from "./Blocks/ClothingBlock";
import { Step1Block, Step2Block } from "./Blocks/StepsBlocks";

export const navBlocks = {
    PLUGINS: "ConsumerDrivenAIPlugins",
    SUSTAINABILITY: "ForASustainableFuture",
    CLOTHING: "TheRightClothing",
    STEPS: "HowItWorks",
    MARKETPLACE: "ElevateYourBrand",
    FOOTER: "TheEnd",
};

export const LandingContent = (props) => {
    return(
        <Landing id="landingContainer">
            <ContentBlock id="topContainer" marginTop={props.variableMargins["top"]}>
                <NavAnchor id={navBlocks["PLUGINS"]} offset={props.windowHeight / 2} />
                <PluginsBlock />
            </ContentBlock>

            <ContentBlock>
                <NavAnchor id={navBlocks["SUSTAINABILITY"]} offset={props.windowHeight / 2} />
                <SustainabilityBlock />
            </ContentBlock>

            <ContentBlock>
                <NavAnchor id={navBlocks["CLOTHING"]} offset={props.windowHeight / 2} />
                <ClothingBlock />
            </ContentBlock>
            
            <ContentBlock>
                <NavAnchor id={navBlocks["STEPS"]} offset={props.windowHeight / 2} />
                <Step1Block />
                <Spacer />
                <Step2Block />
            </ContentBlock>
            
            <ContentBlock id="bottomContainer" marginBottom={props.variableMargins["bottom"]}>
                <NavAnchor id={navBlocks["FOOTER"]} offset={props.windowHeight / 2}/>
                <Footer type="short"/>
            </ContentBlock>
        </Landing>
    )
}

const Spacer = styled.div`
    height: 120px;
`

const Landing = styled.div`
    position:absolute;
    width: 100%;
    height:auto;
    top:0px;
    left:0px;
    margin: auto;
    align-items: top;
    overflow: hidden;
    background:transparent;
`

const LandingParagraph = styled(ParagraphLarge)`
@media ${Device.mobile} {
    font-size: 18px;
    width:350px;
}
`

const ContentBlock = styled.div`
    padding-left: ${props => props.noPadding === true ? "auto" : "5vw" };
    padding-right: ${props => props.noPadding === true ? "auto" : "5vw" };
    margin-top: ${props => props.marginTop ? props.marginTop+"px" : "auto"};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom+"px" : "300px"};
    display: ${props => props.display ? props.display : "flex"};
    flex-direction: column;
    align-items: center;
    position: relative;

    @media ${Device.mobile} {
        min-width:350px;
        width: 90vw;
        margin-left:auto;
        margin-right:auto;
        margin-top: ${props => props.marginTop ? (props.marginTop * 0.75)+"px" : "auto" };
        margin-bottom: ${props => props.marginBottom ? props.marginBottom+"px" : "225px"};
    }
`

const NavAnchor = styled.div`
    position: absolute; 
    bottom: calc(50% - 10px + ${props => props.offset+"px"});
`

const BulletPoint = styled.div`
    background-color: black;
    color: white;
    border-radius: 100px;
    height: 80px;
    width: 80px;
    display: flex;
    margin: auto auto 35px auto;
    justify-content: center;
    align-items: center;
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    z-index:1;

    @media ${Device.mobile} {
        height: 62px;
        width: 62px;
    }
`

/** img */

const ImageContainer = styled.div`
    display:none;
    position: relative;
    background-color: transparent;
    height:0px;
    z-index:-1;
    left: ${props => props.leftOffset ? (props.windowWidth / 2) - props.leftOffset+"px" : "none"};
    right: ${props => props.rightOffset ? (props.windowWidth / 2) - props.rightOffset+"px" : "none"};
    top: ${props => props.topOffset ? props.topOffset+"px" : "none"};
    bottom: ${props => props.bottomOffset ? props.bottomOffset+"px" : "none"};
    
    @media ${Device.mobile} {
        left: ${props => props.mLeftOffset ? props.mLeftOffset : "none"};
        right: ${props => props.mRightOffset ? props.mRightOffset : "none"};
        top: ${props => props.mTopOffset ? props.mTopOffset+"px" : "none"};
        bottom: ${props => props.mBottomOffset ? props.mBottomOffset+"px" : "none"};
    }
`
/* Deprecated */

const LandingButton = styled.a`
    margin: 80px auto auto auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding:16px 24px;
    gap: 16px;
    color: ${props => props.light ? "black" : "white" };
    margin:auto;
    width: ${props => props.width ? props.width : "350px" };
    height: 60px;

    @media ${Device.mobile} {
        margin: auto auto auto auto;
    }

    
    font-family: 'Romana';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    
    background: ${props => props.light ? "white" : "black" };
    border: 1.5px solid black;
    border-radius: 30px;

    /* identical to box height, or 29px */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;
    text-decoration:none;

    &:hover{
        color: ${props => props.light ? "white" : "black" };
        background-color: ${props => props.light ? "black" : "white" };
        border: 1.5px solid black;
    }
`
