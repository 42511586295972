import { TextBody } from "../../ui/Landing/LandingStyles"
import styled from "styled-components"

export const Preview = (props) => {
    const imageURL = props.item.articleImage?.url

    return (
        <StyledPreview onClick={() => props.handleClick(props.item.sys.id)}>
            <StyledImage src={imageURL}/>
            <TextBody>{props.item.title}</TextBody>
            {props.item.contentfulMetadata?.tags?.map((tag, index)=>
                <p>{tag.name}</p>
            )}
        </StyledPreview>
    )
}

const StyledPreview = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px;
    margin-left: 12px;
    margin-right: 12px;
    height: 700px;
    width: 430px;
    background: #eeeeee;
`

const StyledImage = styled.img`
    background: gray;
    border-style: none;
    height: 515px;
    width: 380px;
    position: relative;
    object-fit: cover;
    z-index: 4;
`