import React from "react";
import styled from "styled-components";

import { HeadingH5, HeadingH6 } from "../../../Utility/HeaderStyles";
import { ParagraphSmall } from "../../../Utility/TextStyles";
import { CircularButton, PrimaryButton } from "../../Buttons/Buttons";

import TeamTestIMG from "./TeamTestImage.png"

import ArrowLB from "./../../Buttons/img/left-arrow-black.svg"
import ArrowRB from "./../../Buttons/img/right-arrow-black.svg"
import ArrowLG from "./../../Buttons/img/left-arrow-grey.svg"
import ArrowRG from "./../../Buttons/img/right-arrow-grey.svg"

export const TeamContainer = () => {
    return(
        <Container>
            <CardContainer>
                <TeamCard 
                    name='Signý Ylva Árnadóttir'
                    title='Developer'
                    description='A witty and fun description about oneself related to what you bring into the project; so the reader knows more about you and what you do: max 4 lines'
                    image={TeamTestIMG}
                    />
                <TeamCard 
                    name='Signý Ylva Árnadóttir'
                    title='Developer'
                    description='A witty and fun description about oneself related to what you bring into the project; so the reader knows more about you and what you do: max 4 lines'
                    image={TeamTestIMG}
                />
                <TeamCard 
                    name='Signý Ylva Árnadóttir'
                    title='Developer'
                    description='A witty and fun description about oneself related to what you bring into the project; so the reader knows more about you and what you do: max 4 lines'
                    image={TeamTestIMG}
                />
                <TeamCard 
                    name='Signý Ylva Árnadóttir'
                    title='Developer'
                    description='A witty and fun description about oneself related to what you bring into the project; so the reader knows more about you and what you do: max 4 lines'
                    image={TeamTestIMG}
                />
                <TeamCard 
                    name='Signý Ylva Árnadóttir'
                    title='Developer'
                    description='A witty and fun description about oneself related to what you bring into the project; so the reader knows more about you and what you do: max 4 lines'
                    image={TeamTestIMG}
                />
            </CardContainer>
            <TeamButtons />
        </Container>
    )
}

const TeamCard = (props) => {
    return(
        <Card>
            <CardImage src={props.image} />
            <CardText>
                <CardTitle>
                    <HeadingH5>{props.name}</HeadingH5>
                    <HeadingH6>{props.title}</HeadingH6>
                </CardTitle>
                <CardParagraph>{props.description}</CardParagraph>
            </CardText>
        </Card>
    )
}

const TeamButtons = () => {
    return(
        <>
        <CircularButton icon={ArrowLB} />
        <PrimaryButton state='enabled' />
        <CircularButton icon={ArrowRB} />
        </>
    )
}

const Container = styled.div`
    margin-top:80px;
`

const Card = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:0;
    gap:24px;

    width:320px;
    height:460px;
`

const CardTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width:100%;
`

const CardText = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    text-align:center;
    padding:0px;
    margin:0px;
    gap:16px;
`

const CardImage = styled.img`
    object-fit:cover;
    height: 230px;
    width: 230px;
    border-radius:50%;
`
const CardParagraph = styled(ParagraphSmall)`
    margin:0px;
`

const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 80px;
`